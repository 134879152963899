import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from "styled-components";
import * as S from './style';
import { useStyles, CssTextField } from "./style";
import {
    CardMedia,
    Card,
    CardActions,
    CardContent,
    Button,
    Typography,
    Link
} from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import {
    CardBgr
} from "components";
import decoration from 'assets/img/heading-decoration-bg.png';
import viewMore from 'assets/img/view-more.png';
import sumenh from 'assets/img/sumenh.jpg';
import tamnhin from 'assets/img/tamnhin.jpg';
import intro from 'assets/img/intro.jpg';
import { postContact } from 'apis/Contact';



const movebgAnimation = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 4000px 0;
  }
`
const Decoration = styled.div`
    &:before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-image: url(${decoration});
        background-repeat: repeat;
        animation: ${movebgAnimation} 0s linear infinite;
    }
`


function Introduce(props: any): React.ReactElement {
    const { data } = props
    const classes = useStyles();
    const history = useHistory();
    const nameRef = useRef(null)
    useEffect(() => {
        // console.log('nameRef :>> ', nameRef);
    }, [nameRef])

    return (
        <>
            <div className={classes.wrapperIntroduce}>
                <div className={'category'}>
                    <div className="category-title">
                        Lời chào từ Ban lãnh đạo
                    </div>
                    <div className='category-body'>
                        <p>Công ty cổ phần Viễn thông Điện lực Hà Nội xin gửi tới Quý Khách hàng lời chào trân trọng.</p>
                        <p>
                            Chúng tôi là đơn vị hoạt động trong lĩnh vực tư vấn thiết kế và xây lắp điện có đội ngũ cán bộ công nhân viên lành nghề, dày dạn kinh nghiệm với một thái độ làm việc có trách nhiệm và chuyên nghiệp nhất cùng trang thiết bị hiện đại đã thực hiện các công trình điện lớn trong nhiều năm qua.
                        </p>
                        <p>
                            Thành lập từ tháng 01/2004 cho đến nay, cùng với sự chuyển đổi phát triển của đất nước, với nỗ lực không ngừng Công ty cổ phần Viễn thông Điện lực Hà Nội đã đi đúng hướng và xây dựng cho mình thương hiệu uy tín trong lĩnh vực điện.
                        </p>
                        <p>Với tiêu chí hàng đầu “AN TOÀN, CHẤT LƯỢNG, ĐẢM BẢO TIẾN ĐỘ ", các công trình đã và đang thực hiện bởi Công ty cổ phần Viễn thông Điện lực Hà Nội liên tục giành được sự tin tưởng của Quý khách hàng. Và hơn nữa Chúng tôi rất mong tiếp tục nhận được sự cộng tác chặt chẽ của Quý khách hàng. </p>

                        <p>Trân trọng.</p>

                    </div>
                </div>

                {/* <div className='row'>
                    <div className='col tnsm'>
                        <img src={sumenh} data-src="sumenh" alt="Sứ mệnh" className=" lazyloaded" />
                        <div className='tnsm-core bg-main-color-2'>
                            <div className="tnsm-title">
                                Sứ mệnh
                            </div>
                            <p className="tnsm-body">Đem lại những giải pháp và chất lượng xây dựng tốt nhất nhằm đáp những sự kỳ vọng của các đối tác và khách hàng, góp phần giúp các đối tác và khách hàng đạt được thành công.<br />Tạo nên những công trình lớn, đẹp và chất lượng mang tầm vóc quốc tế ngay trên mảnh đất Việt Nam. Góp phần đưa ngành công nghiệp xây dựng cảng biển của quốc gia lớn mạnh sánh ngang tầm thế giới, thúc đẩy công nghiệp hóa, hiện đại hóa đất nước.</p>
                        </div>
                    </div>
                    <div className='col tnsm'>
                        <img src={tamnhin} data-src="tamnhin" alt="Tầm nhìn" className=" lazyloaded" />
                        <div className='tnsm-core tnsm-core-l bg-main-color-1'>
                            <div className="tnsm-title">
                                Tầm nhìn
                            </div>
                            <p className="tnsm-body">Trở thành Công ty hàng đầu và tiêu biểu tại Việt Nam trong lĩnh vực thiết kế và thi công cảng biển. <br />Luôn đi đầu trong việc đổi mới và phát triển về hệ thống quản lý, nhân sự, thiết bị, năng lực thi công để ngày càng phát triển vững mạnh, đứng ngang tầm với các đối tác quốc tế. <br />Hợp tác, hỗ trợ các đối tác trong nước cùng phát triển để thúc đẩy ngành thi công cảng biển nói riêng và nền công nghiệp xây dựng nói chung tại Việt Nam.</p>
                        </div>
                    </div>
                </div> */}

                <div className={'category'}>
                    <div className="category-title">
                        Sơ đồ tổ chức
                    </div>
                    <div className='category-body '>
                        <p>
                            Để đáp ứng sự phát triển mạnh mẽ của công ty, nâng cao hiệu quả, chất lượng quản lý, thi công, Công ty cổ phần Viễn thông Điện lực Hà Nội đã từng bước xây dựng bộ máy và quy trình làm việc hiệu quả, chặt chẽ, tuyển dụng và đào tạo những con người tài giỏi, nhiều kinh nghiệm, làm việc với trách nhiệm cao để đảm đương những công việc và vị trí phù hợp.
                        </p>
                    </div>
                    <div className='row'>
                        <div className='col '>
                            <img src={intro} data-src="intro" alt="intro" className="intro-img lazyloaded" />
                        </div>
                    </div>
                </div>

                <div className={'category'}>
                    <div className="category-title">
                        Lĩnh vực kinh doanh
                    </div>
                    <div className='category-body '>
                        <p>
                        Giấy chứng nhận đăng ký kinh doanh số 0101447852 do Sở Kế hoạch và Đầu tư Thành phố Hà Nội cấp đăng ký lần đầu ngày 02 tháng 01 năm 2004, đăng ký thay đổi lần 11 ngày 27 tháng 11 năm 2020, Công ty cổ phần Viễn thông Điện lực Hà Nội hoạt động kinh doanh chính như sau:
                        </p>
                        <ul>
                            <li>
                                Lập hồ sơ mời thầu, đánh giá thầu các công trình xây dựng và công nghiệp
                            </li>
                            <li>
                                Giám sát thi công xây dựng công trình đường dây và trạm biến áp đến 35KV
                            </li>

                            <li>
                                Thí nghiệm, hiệu chỉnh hệ thống điện và thiết bị điện đến 110KV
                            </li>
                            <li>
                                Tư vấn, lập dự án đầu tư, xây lắp. thiết kế đường dây và trạm biến áp đến 110KV
                            </li>
                            <li>
                                Kinh doanh vật tư, thiết bị ngành điện
                            </li>
                            <li>
                                Thi công, xây lắp đường dây và trạm biến áp đến 220KV
                            </li>

                            <li>
                                Cho thuê kho bãi và lưu giữ hàng hóa
                            </li>


                        </ul>
                    </div>
                </div>
            </div>
        </>

    );
}



export default (Introduce);