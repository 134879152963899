import React from "react";
import styled from 'styled-components'
import PropTypes from "prop-types";

function CustomLabel(props) {
  
  const { labelstyled } = props;

  return (
    <StyledLabel style={labelstyled}>{props.label}</StyledLabel>
  )
}

const StyledLabel = styled.span`
  font-size: 16px;
  font-family: inherit;
`;

CustomLabel.propTypes = {
  labelstyled: PropTypes.object,
}

export default CustomLabel;