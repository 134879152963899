import styled from 'styled-components';

export const Messages = styled.div`
	display: flex;
	align-items: center;
`;

export const Content = styled.div`
	margin-left: 10px;
`;
