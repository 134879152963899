import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  CardMedia,
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import Paper from "../Paper/index";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    "&:hover": {
      "& .content": {
        backgroundColor: "#27c7cd",
        color: "#FFFFFF",
      },
      "& .media": {
        backgroundSize: "105%",
      },
    },
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

function CardBgr(props) {
  const { title, imageurl, src } = props;
  const classes = useStyles();
  return (
    <Card variant="outlined" className={classes.wrapper}>
      <CardMedia
        className={`${classes.media} media`}
        image={imageurl}
        src={src}
      />
      <CardContent className={"content"}>
        <Typography variant="h6" component="h2">
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default CardBgr;
