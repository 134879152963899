import React from 'react';
import { useTranslation } from "react-i18next";
import { Container, Link } from "@material-ui/core";
import { ArrowForwardIos } from '@material-ui/icons';
import * as S from './style';
import { AppBars } from 'components';
import iconAddress from 'assets/img/footer/icon-address.png';
import iconPhone from 'assets/img/footer/icon-phone.png';
import iconMobile from 'assets/img/footer/icon-mobile.png';
import iconMail from 'assets/img/footer/icon-mail.png';
import iconShare from 'assets/img/footer/icon-share.png';
import menus from 'config/constants/menu';


const data2 = [

  {
    title: 'Trang chủ',
    link: '/'
  },
  {
    title: 'Giới thiệu',
    link: '/introduce'
  },
  {
    title: 'Sản phẩm thương mại',
    link: '/product'
  },
  {
    title: 'Dự án thực hiện',
    link: '/project'
  },
  {
    title: 'Tin tức',
    link: '/news'
  },
  {
    title: "Tài liệu",
    link: "/document",
  },
  // {
  //   title: 'Showroom',
  //   link: '/'
  // },
  {
    title: 'Liên hệ',
    link: '/contact'
  },
]

function Footer(): React.ReactElement {
  const { t } = useTranslation("common");
  const menu = menus(t)

  const dataAddress = [
    {
      title: t('footer.address'),
      des: t('footer.address_detail'),
      img: iconAddress
    },
    {
      title: t('footer.phone'),
      des: t('footer.phone_detail'),
      img: iconPhone
    },

    {
      title: t('footer.fax'),
      des: t('footer.fax_detail'),
      img: iconMobile
    },
    {
      title: t('footer.email'),
      des: t('footer.email_detail'),
      img: iconMail
    },
    {
      title: t('footer.website'),
      des: t('footer.website_detail'),
      img: iconMail,
      link: 'http://www.hatec.vn'
    },
  ]
  return (
    <S.Wrapper>
      <Container>
        <S.FooterContent>
          <S.TitleFooter>
            {t('sidebar.name')}
          </S.TitleFooter>

          <S.Content>
            <S.Row>
              <S.Col className="col-md-8">
                <S.TitleContent>
                  {t('footer.contact_info')}
                </S.TitleContent>

                {
                  dataAddress.map((el: any, index: any) => <S.ListItem key={index} >
                    {/* <img src={el.img ?? ''} alt='top-new' /> */}
                    <Link href={el.link ?? '#'} underline="none" className='link-item'>
                      <span>{el.title ?? ''}:&nbsp;
                        {el.des ?? ''}
                      </span>
                    </Link>
                  </S.ListItem>

                  )
                }

              </S.Col>
              <S.Col className="col-md-4">
                <S.TitleContent>
                  {t('footer.access')}
                </S.TitleContent>
                <S.ListItem  >
                    {/* <img src={el.img ?? ''} alt='top-new' /> */}
                    <Link href={'/'} underline="none" className='link-item link' >
                      <ArrowForwardIos />
                      {t('sidebar.home')}
                    </Link>
                  </S.ListItem>
                {
                  menu.map((el: any, index: any) => <S.ListItem key={index} >
                    {/* <img src={el.img ?? ''} alt='top-new' /> */}
                    <Link href={el.link ?? '#'} underline="none" className='link-item link' >
                      <ArrowForwardIos />
                      {el.label ?? ''}
                    </Link>
                  </S.ListItem>

                  )
                }

              </S.Col>
              {/* <S.Col className="col-md-2">
                <S.TitleContent>
                  BAN DỰ ÁN

                </S.TitleContent>
                {
                  data2.map((el: any, index: any) => <S.ListItem key={index} >
                    <img src={el.img ?? ''} alt='top-new' />
                    <Link href={'#'} underline="none" className='link-item' >
                      {el.title ?? ''}

                    </Link>
                  </S.ListItem>

                  )
                }

              </S.Col> */}
            </S.Row>
          </S.Content>

        </S.FooterContent>
      </Container>
      <S.ChatZalo>
        <div className="phonering-alo-ph-circle"></div>
        <div className="phonering-alo-ph-circle-fill"></div>
        <a href="http://zalo.me/691594366836515685">
          <div className="phonering-alo-ph-img-circle"></div>
        </a>
      </S.ChatZalo>
    </S.Wrapper>
  )
}

// Header.propTypes = {
//   leftElement: PropTypes.any,//left elemnt
//   centerElement: PropTypes.any,//center element
//   rightElement: PropTypes.any,//right element
//   styleCustom: PropTypes.any,//styleCustom
//   loading: PropTypes.bool,//loading check
// };

export default Footer;