import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Badges } from 'components';

function Avatars(props) {
	const { avatarProp, size, bgrColor, color, type, dataGroup, maxGroup, badgeProps, avatarPropSmall } = props;

	const styleAvatar = {
		root: {
			width: size,
			height: size,
			backgroundColor: bgrColor,
			color: color,
		},
	};
	const styleGroupAvatar = {
		avatar: {
			width: size,
			height: size,
		},
	};

	const styleBadge = {
		badge: {
			backgroundColor: '#44b700',
			color: '#44b700',
			boxShadow: `0 0 0 2px #fff`,
			'&::after': {
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				borderRadius: '50%',
				animation: '$ripple 1.2s infinite ease-in-out',
				border: '1px solid currentColor',
				content: '""',
				boxSizing: ' border-box',
			},
		},
		'@keyframes ripple': {
			'0%': {
				transform: 'scale(.8)',
				opacity: 1,
			},
			'100%': {
				transform: 'scale(2.4)',
				opacity: 0,
			},
		},
	};
	const SmallAvatar = withStyles((theme) => ({
		root: {
			width: 22,
			height: 22,
			border: `2px solid ${theme.palette.background.paper}`,
		},
	}))(Avatar);
	const StyleAvatar = withStyles(styleAvatar)(Avatar);
	const StyleGroupAvatar = withStyles(styleGroupAvatar)(AvatarGroup);
	const StyledBadge = withStyles(styleBadge)(Badges);

	const listGroup =
		dataGroup &&
		dataGroup.map((item, index) => {
			return <StyleAvatar key={index} {...item}></StyleAvatar>;
		});
	if (type && type === 'Group') {
		return <StyleGroupAvatar max={maxGroup}>{listGroup}</StyleGroupAvatar>;
	}
	if (type && type === 'Badge') {
		return <StyledBadge {...badgeProps} contentprops={<StyleAvatar {...avatarProp} />} />;
	}
	if (type && type === 'AvatarSmall') {
		return (
			<Badges
				{...badgeProps}
				badgeContent={<SmallAvatar {...avatarPropSmall} />}
				contentprops={<StyleAvatar {...avatarProp} />}
			/>
		);
	}
	return <StyleAvatar {...avatarProp}></StyleAvatar>;
}

Avatars.propTypes = {
	avatarProp: PropTypes.object,
	size: PropTypes.number,
	bgrColor: PropTypes.string,
	color: PropTypes.string,
	action: PropTypes.any,
	type: PropTypes.string,
	maxGroup: PropTypes.number,
	dataGroup: PropTypes.array,
	avatarPropSmall: PropTypes.object,
	badgeProps: PropTypes.object,
};

export default Avatars;
