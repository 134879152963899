import React, { memo } from 'react';
import PropTypes from 'prop-types';
// import * as S from './style';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import { List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import {  Stars } from "@material-ui/icons";



function Drawers(props) {
    const { isShow, position, widthdrawer, setDrawer, headItem, listItem, bottomItem } = props;

    const useStyles = makeStyles({
        list: {
            width: widthdrawer || 220,
        },
        fullList: {
            width: 'auto',
        },
    });

    const classes = useStyles();

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                {listItem && listItem.map((v, i) => (
                    <ListItem button component="a" href={v.link} key={i} divider >
                        <ListItemIcon>{v.icon?? <Stars/>}</ListItemIcon>
                        <ListItemText primary={v.label} />
                    </ListItem>
                ))}
            </List>
        </div>
    );

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawer(open);
    };

    return (<Drawer
        anchor={position}
        open={isShow}
        onClose={toggleDrawer(false)}
        ModalProps={{
            container: document.getElementById('root'),
            style: { position: 'absolute' }
        }}>
        {headItem}
        {list(position)}
        {bottomItem}

    </Drawer>)
}


Drawers.propTypes = {
    isShow: PropTypes.any,// isShow drawer
    widthdrawer: PropTypes.number,// width drawer
    position: PropTypes.string,// position drawer
    setDrawer: PropTypes.func,// change show drawer status
    headItem: PropTypes.any,// head item
    listItem: PropTypes.array,// list item
    bottomItem: PropTypes.any,// bottomItem item
    loading: PropTypes.bool,
};

export default memo(Drawers);