import React from 'react';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

function Switchs(props) {
	const { switchProps,onValueSwitch, colorCheck, bgrColorNotCheck } = props;
	const [state, setState] = React.useState(true);

	const handleChange = (event) => {
		setState(event.target.checked );
	};
	const styleSlider = {
		switchBase: {
			color: colorCheck,
			'&$checked': {
				color: colorCheck,
			},
			'&$checked + $track': {
				backgroundColor: colorCheck,
			},
		},
		checked: {},
		track: {backgroundColor: bgrColorNotCheck},
	};
	const StyleSwitch = withStyles(styleSlider)(Switch);
	return (
		<StyleSwitch
			checked={state}
			onChange={(e) => {
				handleChange(e);
				onValueSwitch && onValueSwitch(e.target.checked);
			}}
			{...switchProps}
		/>
	);
}

Switchs.propTypes = {
	switchProps: PropTypes.object,
	inputProps: PropTypes.object,
	color: PropTypes.string,
	styleSlider: PropTypes.object,
	onValueSwitch: PropTypes.func,
	colorCheck: PropTypes.string,
	bgrColorNotCheck: PropTypes.string,
};

export default Switchs;
