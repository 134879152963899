import React from 'react';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import EditIcon from '@material-ui/icons/Edit';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

function SpeedDials(props) {
	const { speedDialProps, actions } = props;

	const styleSpeedDialAction = {
		fab: {},
		fabClosed: {},
		staticTooltip: {},
		staticTooltipClosed: {},
		staticTooltipLabel: {},
		tooltipPlacementLeft: {},
		tooltipPlacementRight: {},
	};
	const StyleSpeedDialAction = withStyles(styleSpeedDialAction)(SpeedDialAction);

	const [open, setOpen] = React.useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	return (
		<SpeedDial
			icon={<SpeedDialIcon openIcon={<EditIcon />} />}
			onClose={handleClose}
			onOpen={handleOpen}
			open={open}
			{...speedDialProps}
		>
			{actions &&
				actions.map((action) => (
					<StyleSpeedDialAction
						key={action.name}
						icon={action.icon}
						tooltipTitle={action.name}
						onClick={handleClose}
					/>
				))}
		</SpeedDial>
	);
}

SpeedDials.propTypes = {
	speedDialProps: PropTypes.object,
	actions: PropTypes.array,
};

export default SpeedDials;
