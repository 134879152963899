import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import PropTypes from 'prop-types';

function Badges(props) {
    let arrProps = {...props};
    const useStyles = makeStyles(() =>
        createStyles({
            root: {
            '& > .MuiBadge-root > .MuiBadge-badge': {
                backgroundColor: props.color,
                color: props.textcolor, 
                minWidth:  props.size,
                padding:  0.3*props.size,
                height:  props.size,
                fontSize: 0.6*props.size,
                borderRadius: 0.5*props.size
            }}
        })
    );
    const arrDefauft = ["default","error","primary","secondary"];
    const isDefault = arrDefauft.find(item => item === props.color);
    if(!isDefault){
        delete arrProps["color"];
    }
    const classes = useStyles();

    return <span className={classes.root}>
                <Badge {...arrProps}>{props.contentprops}</Badge>
            </span>
    
}

Badges.propTypes = {
    children: PropTypes.node,
    badgeContent: PropTypes.node,
    anchorOrigin: PropTypes.object,
    max: PropTypes.number,
    overlap : PropTypes.string,
    textcolor: PropTypes.string,
    size: PropTypes.number,
    contentprops: PropTypes.any
};

export default Badges;