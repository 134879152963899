import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";

function ConfirmAlert(props) {

  const { icon, severity, colorStyle, color, bgAlert } = props;

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      background: bgAlert,
      marginTop: theme.spacing(2),
      '.MuiAlert-message': {
        color: colorStyle
      }
    }, 
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Alert icon={icon} severity={severity} color={color}>
        {props.children}
      </Alert>
    </div>
  );
}

Alert.propTypes = {
  icon: PropTypes.node,
  severity: PropTypes.string,
  bgAlert: PropTypes.string,
  colorStyle: PropTypes.string,
};

export default ConfirmAlert;
