import React, { Children, useEffect, useState } from 'react';
import { Menu } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import {
  makeStyles,
  ClickAwayListener,
} from '@material-ui/core';
import {
  Mobile,
  Default
} from "components/Responsive";
import * as S from './style';
import {
  Btn,
  Drawers,
} from 'components';
import { useHistory } from "react-router-dom";
import menus from 'config/constants/menu';
import reactLocalStorage from 'utils/localStorageConfig/localStorage';

import { HeaderForm, Footer } from 'containers';
import Energy from 'assets/img/logo.jpg';
import langEn from 'assets/img/lang-en.png';
import langVn from 'assets/img/lang-vn.png';
import Sidebar from './Sidebar';


interface Props {
  children?: any
}

// eslint-disable-next-line react-hooks/rules-of-hooks



const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  logo: {
    // maxWidth: 140,
    maxHeight: 70,
  },
  lang: {
    display: "flex",
  },
  btnLang: {

  },
  nameCompany: {
    marginLeft: 5,
    maxWidth: 250,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 15,
    "@media (max-width: 767px)": {
      fontSize: 12,
    },
  }
}));


function MixLayout(props: Props): React.ReactElement {
  const { children } = props;
  const classes = useStyles();
  const [isDrawer, setDrawer] = useState(false);
  const history = useHistory();
  const { t } = useTranslation("common");
  const { i18n } = useTranslation('common');

  const menu = menus(t)
  const listOptions = [
    {
      src: langVn,
      name: t("lang.nameVn"),
      lang: 'vn'
    },
    {
      src: langEn,
      name: t("lang.nameEn"),
      lang: 'en'
    }
  ]
  const [activeDropdown, setActiveDropdown] = useState(false);
  const [langActive, setLangActive] = useState(listOptions[0])

  const anchorRef: any = React.useRef(null);

  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);

  const onHandleClick = (item?: any) => {
    setLangActive(item)
    i18n.changeLanguage(item.lang)
    getLangSelected(item)
    setActiveDropdown(false)

    // setAnchorEl(null);
  }

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current?.contains(event.target)) {
      return;
    }
    setActiveDropdown(false)
  };

  useEffect(() => {
    let lang = JSON.parse(reactLocalStorage.get("langElectricity", JSON.stringify(langActive)))
    i18n.changeLanguage(lang?.lang ?? 'vn')
    setLangActive(lang)
    // eslint-disable-next-line
  }, []);

  const getLangSelected = (e?: any) => {
    i18n.changeLanguage(e.lang)
    reactLocalStorage.set("langElectricity", JSON.stringify(e))
  }

  return (
    <S.Wrapper>
      <HeaderForm
        color="default"
        leftElement={
          <>
            <img onClick={() => history.push('/')} className={classes.logo} src={Energy} alt="logo" />
            <div className={classes.nameCompany} onClick={() => history.push('/')}>
              {<span>{t('sidebar.name1')} <br />{t('sidebar.name2')}</span>}
            </div>
          </>

        }
        centerElement={
          <Default>
            <Sidebar />
          </Default>
        }
        rightElement={
          // <Btn
          //     type="ICON_BUTTON"
          //     aria-controls="bar-menu"
          //     iconprops={<AccountCircle style={{ color: '#FFFFFF' }} />}
          // />
          <>
            <S.Languages>
              <div className="language">
                <div className="language-block" onClick={() => setActiveDropdown(!activeDropdown)}
                  ref={anchorRef}

                >
                  {/* <span className="language-title">Languages:</span> */}
                  <img
                    src={langActive.src}
                    alt="language"
                  />
                </div>
                {activeDropdown ?
                  <ClickAwayListener onClickAway={handleClose}>
                    <ul className="pop-content">
                      {
                        listOptions.map((item, index) => {
                          return (
                            <li key={index}>
                              <button onClick={() => onHandleClick(item)}>
                                <img
                                  src={item.src}
                                  alt={item.name}
                                />
                                <span>{item.name}</span>
                              </button>
                            </li>
                          )
                        })
                      }
                    </ul>
                  </ClickAwayListener>

                  : ''}
              </div>
            </S.Languages>
            {/* <div className={classes.lang}>
          <Btn
             type="ICON_BUTTON"
             aria-label="menu"
             color="#1b2c38"
             iconprops={<img src={langVn} alt='lang' />}
             // onClick={() => setDrawer(!isDrawer)}
           />
            <Btn
              type="ICON_BUTTON"
              aria-label="menu"
              color="#1b2c38"
              iconprops={<img src={langEn} alt='lang' />}
              // onClick={() => setDrawer(!isDrawer)}
            />
        
          </div> */}
            <Mobile>
              <Btn
                type="ICON_BUTTON"
                aria-label="menu"
                color="#1b2c38"
                iconprops={<Menu />}
                onClick={() => setDrawer(!isDrawer)}
              />
            </Mobile>
          </>
        }
      />
      <Drawers
        isShow={isDrawer}
        position={"left"}
        widthdrawer={380}
        setDrawer={setDrawer}
        headItem={
          <S.Head>
            {/* Gobit */}
          </S.Head>
        }
        listItem={menu}
      // bottomItem={
      //   <Btn name="Toogle"
      //     color="primary"
      //     variant="contained"
      //   />
      // }
      />
      {children}
      <Footer />
    </S.Wrapper>
  )
}

export default MixLayout;