import React from 'react';
import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import * as S from './style';
import { useStyles } from "./style";
import { Container, Link, CardActions, CardContent, Button, Typography } from '@material-ui/core';
import {
    CardBgr
} from "components";
import { useHistory } from "react-router-dom";
import decoration from 'assets/img/heading-decoration-bg.png';
import viewMore from 'assets/img/view-more.png';
import viewMoreGrey from 'assets/img/view-more-grey.png';



const movebgAnimation = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 4000px 0;
  }
`
const Decoration = styled.div`
    &:before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-image: url(${decoration});
        background-repeat: repeat;
        animation: ${movebgAnimation} 0s linear infinite;
    }
`


function News(props: any): React.ReactElement {
    const { data } = props
    const classes = useStyles();
    const history = useHistory();

    const { t } = useTranslation("common");
    const { i18n } = useTranslation('common');
    const language = i18n.language

    console.log('language', language)
    // const findNewsTop = data.findIndex((el: any) => el.img)
    // const dataFind = data[findNewsTop]
    // const listNews = data.filter((el: any) => !el.img)
    const listNews = data.slice(1)
    const dataNew = data[0] ?? []

    return (
        <>
            <div className={`${classes.title} ${classes.mb30}`} >
                {t('text.new_hot')}
            </div>
            <div className={classes.row}>
                <div className={classes.col}>
                    <div className={classes.itemNews}>
                        {data?.length > 0 && data[0].thumbnail && <img src={data[0].thumbnail ?? ''} alt='top-new' className={classes.imgNews} />}
                        <Link
                            onClick={() => history.push(`/news-detail/${data[0]._id}`, { id: data[0]._id, data: data[0] })}
                            underline="none" className={classes.linkNews} >
                            {language === 'en' ? dataNew?.titleEN : dataNew?.title}

                        </Link>
                    </div>

                </div>
                <div className={classes.col}>
                    <div className={`${classes.itemNews} bd-top0`} >
                        <span className="hot-news-sub-title">
                            {t('text.other_news')}
                        </span>
                        <Link href={'/news'} underline="none" className={`view-more-link`} >
                            {t('text.view_more')}
                            <img src={viewMore} alt='viewMore' />

                        </Link>
                    </div>
                    {
                        listNews.map((item: any, i: any) => <div className={classes.itemNews} key={i}>
                            <img src={viewMoreGrey} alt='viewMoreGrey' />

                            <Link
                                // href={item?.link ?? '#'} 
                                onClick={() => history.push(`/news-detail/${item._id}`, { id: item._id, data: item })}
                                underline="none" className={classes.linkNews} >
                                {language === 'en' ? item?.titleEN : item?.title}
                            </Link>
                        </div>)
                    }


                </div>
            </div>
        </>

    );
}



export default (News);