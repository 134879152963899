import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import Chip from '@material-ui/core/Chip';
// import Paper from '@material-ui/core/Paper';
import Chip from 'components/Chip';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
}));

function ChipsArray(props) {
    const classes = useStyles();
    const { customChip, listData } = props;
    const [chipData, setChipData] = useState(listData);

    const handleDelete = (chipToDelete) => () => {
        setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    };

    return (
        <div component="ul" className={classes.root}>
            {chipData && chipData.map((data) => {
                return (
                    <li key={data.key}>
                        <Chip
                            avatar={data.icon}
                            {...customChip}
                            label={data.label}
                            onDelete={data.isDelete ? handleDelete(data) : undefined}
                            className={classes.chip}
                        />
                    </li>
                );
            })}
        </div>
    );
}

ChipsArray.propTypes = {
    listData: PropTypes.array,
    customChip: PropTypes.any,
    loading: PropTypes.bool,
};

export default ChipsArray;