import Paper from "@material-ui/core/Paper";
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

function PaperComponent(props) {
  const { elevation } = props;
  const classes = useStyles();
  return (
    <div classes={classes.root}>
      <Paper elevation={elevation} {...props}>
        {props.children}
      </Paper>
    </div>
  );
}

Paper.PropTypes = {
  elevation: PropTypes.number,
  children: PropTypes.node,
};

export default PaperComponent;
