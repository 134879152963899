import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Btn } from 'components';
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function Dialogs(props) {
	const { dialogProp, title, content, styleTitle, styleContent, listBtn, positionBtn } = props;
	const styleDialogTitle = {
		root: styleTitle,
	};
	const styleDialogContent = {
		root: styleContent,
	};
	const positionBtns = (e) => {
		switch (e) {
			case 'center':
				return 'center';
			case 'left':
				return 'flex-start';
			case 'right':
				return 'flex-end';
			default:
		}
	};
	const styleDialogAction = {
		root: {
			padding: 24,
			paddingTop: 12,
			justifyContent: positionBtns(positionBtn),
		},
	};
	const StyleDialogTitle = withStyles(styleDialogTitle)(DialogTitle);
	const StyleDialogContent = withStyles(styleDialogContent)(DialogContentText);
	const StyleDialogActions = withStyles(styleDialogAction)(DialogActions);

	return (
		<Dialog TransitionComponent={Transition} {...dialogProp}>
			{title && <StyleDialogTitle id="dialog-title">{title}</StyleDialogTitle>}
			{content && (
				<DialogContent>
					<StyleDialogContent id="dialog-description">{content}</StyleDialogContent>
				</DialogContent>
			)}
			<StyleDialogActions>
				{listBtn.confirm && <Btn {...listBtn.confirm} />}
				{listBtn.cancel && <Btn {...listBtn.cancel} />}
			</StyleDialogActions>
		</Dialog>
	);
}

Dialogs.propTypes = {
	dialogProp: PropTypes.object,
	title: PropTypes.string,
	content: PropTypes.string,
	styleTitle: PropTypes.object,
	styleContent: PropTypes.any,
	listBtn: PropTypes.object,
	positionBtn: PropTypes.string,
};

export default Dialogs;
