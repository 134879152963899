import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  background-color: #105ea8;
  color: #cddbfe;
  text-align: left;
`;

export const FooterContent = styled.div`
  width: 100%;
  padding: 60px 0 40px;
`;

export const TitleFooter = styled.div`
  width: 100%;
  margin-bottom: 48px;
  line-height: 1.3em;
  font-weight: bold;
  color: #ffffff;
  font-size: 1.75rem;
  text-align: left;
  text-transform: uppercase;
  @media (max-width: 767px) {
    margin-bottom: 32px;
    font-size: 1.25rem;
  }
`;

export const Content = styled.div`
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
`;

export const Col = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  @media (min-width: 768px) {
    &.col-md-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    &.col-md-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    &.col-md-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    &.col-md-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
  }
`;

export const TitleContent = styled.div`
  font-size: 17px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 20px;
  line-height: 1.3em;
  text-transform: uppercase;
`;

export const ListItem = styled.div`
  /* margin-bottom: 15px; */
  font-size: 16px;
  display: flex;
  /* &:hover {
    transform: translateX(5px);
  } */
  & .link-item {
    display: flex;
    color: #cddbfe;
  }
  & img {
    margin-right: 8px;
  }
  & .link {
    &:hover {
      color: #f68b3d;
    }
    & svg {
      width: 12px;
    }
  }
`;

export const ChatZalo = styled.div`
  position: fixed;
  bottom: -40px;
  right: 0;
  width: 170px;
  height: 170px;
  z-index: 10;
  & .phonering-alo-ph-circle {
    width: 160px;
    height: 160px;
    top: -19px;
    left: 16px;
    position: absolute;
    background-color: transparent;
    border-radius: 100% !important;
    /* border: 2px solid rgba(30, 30, 30, 0.4);
    border: 2px solid #bfebfc 9; */
    opacity: 0.1;
    /* animation: phonering-alo-circle-anim 1.2s infinite ease-in-out; */
    transition: all 0.5s;
    transform-origin: 50% 50%;
    border-color: #bfebfc;
    opacity: 0.5;
  }

  & .phonering-alo-ph-circle-fill {
    /* width: 100px;
    height: 100px; */
    top: 16px;
    left: 46px;
    position: absolute;
    background-color: #000;
    border-radius: 100% !important;
    /* border: 2px solid transparent; */
    /* animation: phonering-alo-circle-fill-anim 2.3s infinite ease-in-out; */
    transition: all 0.5s;
    transform-origin: 50% 50%;
    background-color: rgba(0, 175, 242, 0.5);
  }

  & .phonering-alo-ph-img-circle {
    width: 60px;
    height: 60px;
    top: 35px;
    left: 65px;
    position: absolute;
    background: rgba(30, 30, 30, 0.1) url(../phone-ring.png) no-repeat center
      center;
    background-size: contain;
    border-radius: 100% !important;
    border: 2px solid transparent;
    /* animation: phonering-alo-circle-img-anim 1s infinite ease-in-out; */
    transform-origin: 50% 50%;
  }

  @keyframes phonering-alo-circle-anim {
    0% {
      transform: rotate(0) scale(0.5) skew(1deg);
      opacity: 0.1;
    }

    30% {
      transform: rotate(0) scale(0.7) skew(1deg);
      opacity: 0.5;
    }

    100% {
      transform: rotate(0) scale(1) skew(1deg);
      opacity: 0.1;
    }
  }

  @keyframes phonering-alo-circle-fill-anim {
    0% {
      transform: rotate(0) scale(0.7) skew(1deg);
      opacity: 0.2;
    }

    50% {
      transform: rotate(0) scale(1) skew(1deg);
      opacity: 0.2;
    }

    100% {
      transform: rotate(0) scale(0.7) skew(1deg);
      opacity: 0.2;
    }
  }

  @keyframes phonering-alo-circle-img-anim {
    0% {
      transform: rotate(0) scale(1) skew(1deg);
    }

    10% {
      transform: rotate(-25deg) scale(1) skew(1deg);
    }

    20% {
      transform: rotate(25deg) scale(1) skew(1deg);
    }

    30% {
      transform: rotate(-25deg) scale(1) skew(1deg);
    }

    40% {
      transform: rotate(25deg) scale(1) skew(1deg);
    }

    50% {
      transform: rotate(0) scale(1) skew(1deg);
    }

    100% {
      transform: rotate(0) scale(1) skew(1deg);
    }
  }
`;
