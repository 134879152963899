import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Btn from '../Button';
import PropTypes from 'prop-types';
import * as S from './style';

function KakaoMap(props) {
	const {
		colorStyle,
		bgAlert,
		width,
		height,
		latitude,
		longitude,
		btnZoom,
		markerProps,
		setPositionMarker,
		isMarker,
	} = props;

	const useStyles = makeStyles((theme) => ({
		root: {
			width: '100%',
			background: bgAlert,
			marginTop: theme.spacing(2),
			'.MuiAlert-message': {
				color: colorStyle,
			},
		},
	}));
	const classes = useStyles();
	const [map, setMap] = useState(null); // To use the map object globally, use the store.
	const [coordinate] = useState({ lat: latitude, lng: longitude }); //setCoordinate
	useEffect(() => {
		mapInit();
		// eslint-disable-next-line
	}, []);
	useEffect(() => {
		if (isMarker) {
			setMarker();
		}
		// eslint-disable-next-line
	}, [isMarker]);
	/**
	 * Kakao Map Initialize
	 * Documents : https://apis.map.kakao.com/web/documentation/
	 * */
	const mapInit = () => {
		if (window.kakao !== null && window.kakao !== undefined && window.kakao.maps !== undefined) {
			// DOM Reference.
			let container = document.getElementById('map');
			// Default Options.
			let options = {
				center: new window.kakao.maps.LatLng(coordinate.lat, coordinate.lng),
				level: 3,
			};

			// Create map and return map object
			setMap(new window.kakao.maps.Map(container, options));
		}
	};
	/**
	 * Test : Set Marker & Move Center.
	 * */

	let imageSrc;
	let imageSize;
	let imageOption;
	let markerImage;

	if (window.kakao !== null && window.kakao !== undefined && window.kakao.maps !== undefined) {
		imageSrc = markerProps && markerProps.src; // 마커이미지의 주소입니다
		imageSize = markerProps && new window.kakao.maps.Size(markerProps.width, markerProps.height); // 마커이미지의 크기입니다
		imageOption = { offset: new window.kakao.maps.Point(markerProps.width / 2, markerProps.height) };
		markerImage = new window.kakao.maps.MarkerImage(imageSrc, imageSize, imageOption);
	}
	const setMarker = () => {
		let coor = new window.kakao.maps.LatLng(isMarker[0], isMarker[1]);
		// Create maker.
		let marker = new window.kakao.maps.Marker({
			position: coor,
		});
		markerProps.src && marker.setImage(markerImage);
		// Marker on the map.
		marker.setMap(map);

		// 지도 중심을 부드럽게 이동시킵니다
		map.panTo(coor);
	};
	if (map) {
		var marker = new window.kakao.maps.Marker({
			// 지도 중심좌표에 마커를 생성합니다
			position: map.getCenter(),
		});
		window.kakao.maps.event.addListener(map, 'click', function (mouseEvent) {
			const newLatLng = mouseEvent.latLng;

			// Marker on the map.
			// Create maker.
			markerProps.src && marker.setImage(markerImage);
			marker.setPosition(newLatLng);
			setPositionMarker(newLatLng);
			marker.setMap(map);

			// map.panTo(newLatLng);
			// 지도 중심을 부드럽게 이동시킵니다
			console.log('mouseEvent', mouseEvent);
		});
	}

	function zoomIn() {
		map.setLevel(map.getLevel() - 1);
	}

	// 지도 확대, 축소 컨트롤에서 축소 버튼을 누르면 호출되어 지도를 확대하는 함수입니다
	function zoomOut() {
		map.setLevel(map.getLevel() + 1);
	}

	return (
		<div className={classes.root}>
			<S.MapWrap style={{ width: width, height: height }}>
				<S.Map id="map"></S.Map>
				<S.Zoom>
					{btnZoom && btnZoom.zoomIn && <Btn onClick={zoomIn} {...btnZoom.zoomIn} />}
					{btnZoom && btnZoom.zoomOut && <Btn onClick={zoomOut} {...btnZoom.zoomOut} />}
				</S.Zoom>
			</S.MapWrap>
		</div>
	);
}

Alert.propTypes = {
	bgAlert: PropTypes.string,
	colorStyle: PropTypes.string,
	btnZoom: PropTypes.object,
	latitude: PropTypes.number,
	longitude: PropTypes.number,
	setPositionMarker: PropTypes.func,
	width: PropTypes.string,
	height: PropTypes.string,
	markerProps: PropTypes.object,
	isMarker: PropTypes.array,
};

export default KakaoMap;
