import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import * as S from './style';
function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Snackbars(props) {
	const {
		snackbarProp,
		setIsAlert,
		isShow,
		vertical,
		horizontal,
		message,
		type,
		severity,
		icon,
		bgrColor,
		color,
		action,
	} = props;
	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			setIsAlert(false);
			return;
		}

		setIsAlert(false);
	};
	const styleSnackbar = {
		root: {
			backgroundColor: bgrColor,
			color: color,
		},
	};
	const StyleSnackbar = withStyles(styleSnackbar)(SnackbarContent);
	const StyleAler = withStyles(styleSnackbar)(Alert);

	if (type && type === 'Alert') {
		return (
			<Snackbar
				open={isShow}
				onClose={handleClose}
				anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
				autoHideDuration={4000}
				{...snackbarProp}
			>
				<StyleAler onClose={handleClose} severity={severity} icon={icon}>
					{message}
				</StyleAler>
			</Snackbar>
		);
	}
	return (
		<Snackbar
			open={isShow}
			onClose={handleClose}
			anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
			autoHideDuration={4000}
			{...snackbarProp}
		>
			<StyleSnackbar
				message={
					<S.Messages>
						{icon}
						<S.Content>{message}</S.Content>
					</S.Messages>
				}
				action={action}
			></StyleSnackbar>
		</Snackbar>
	);
}

Snackbars.propTypes = {
	snackProp: PropTypes.object,
	vertical: PropTypes.string,
	horizontal: PropTypes.string,
	severity: PropTypes.string,
	type: PropTypes.string,
	icon: PropTypes.any,
	message: PropTypes.any,
	bgrColor: PropTypes.string,
	color: PropTypes.string,
	action: PropTypes.any,
};

export default Snackbars;
