import styled from "styled-components";
import { Container } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';

export const Custom = styled.div``;

export const Wrapper = styled.div`
    .MuiButtonBase-root:{
        left: 0 !important;
  }
`;


export const CustomContainer = withStyles({
    root: {
        paddingLeft: 0,
        paddingRight: 0
    },

  })(Container);