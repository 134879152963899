import React, { memo } from 'react';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';
import StyledTableData from './StyledTableData';

const StyledMessage = styled.div`
	padding: 1rem;
	width: 100%;
	display: flex;
	justify-content: center;
	background-color: rgba(0,0,0,0.03);
	>p {
		margin-bottom: 0;
		padding: 1rem 0;
	}
`;

function TableData(props) {

  const noDataComponent = (
    <StyledMessage>
      <p>No Data</p>
    </StyledMessage>
  );

  return <StyledTableData>
    <DataTable
      {...props}
      noDataComponent={noDataComponent}
    />
  </StyledTableData>;
}

export default memo(TableData);
