import styled from 'styled-components';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const SearchWrapper = styled.div`
	display: inline-block;
	position: relative;
  margin-bottom: 20px;
`;

export const Input = styled.input`
  width: 100%;
  min-height: 32px;
  height: auto!important;
  line-height: 32px;
  border-radius: 2px;
  border: 1px solid #CDCDCD!important;
  padding: 5px 10px;
  outline: 0;
  &:hover, &:focus {
  	border: 1px solid #4D4D4D !important;
  }
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 0.3;
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    opacity: 0.3;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    opacity: 0.3;
  }
`;


export const SearchBoxIcon = styled(SearchIcon)`
  position: absolute;
  cursor: pointer;
  right: -40px;
  top: 12px;
  color: #797979;
`;


export const RemoveIcon = styled(ClearIcon)`
  position: absolute;
  cursor: pointer;
  right: -35px!important;
  top: 10px;
  color: #797979;
`;

export default SearchWrapper;