import styled from 'styled-components'

const StyledTableData = styled.div`
	overflow: auto;
	margin-bottom: 6rem;
	margin-left: 1.5rem;
	margin-right: 1.5rem;
	box-shadow: 1px 2px 5px 1px rgba(206,206,206,0.5);
  .rdt_Table {
    height: auto !important;
    .rdt_TableHead {
			border-top: 1px solid rgba(206,206,206,0.5);
			background-color: #fefefe;
			.rdt_TableHeadRow {
        .rdt_TableCol {
          input {
            width: 16px;
            height: 16px;
          }
        }
				>div {
					border-top: none;
					font-weight: 600;
					text-align: center;
					font-size: 14px;
					color: ${props => props.theme.titleHeaderTableColor};
					&.uppercase {
						text-transform: uppercase;
					}
					&:first-child {
						border-radius: 15px 0 0 0;
						border-left: none;
					}
					&:last-child {
						border-radius: 0 15px 0 0;
					}
				}
		  }
		}
    .rdt_TableBody {
			height: auto !important;
			.rdt_TableRow {
				text-align: center;
				background-color: ${props => props.theme.whiteColor};
        cursor: pointer;
        font-size: 14px;
        .rdt_TableCell {
          input {
            width: 16px;
            height: 16px;
            margin-top: 5px !important;
          }
        }
        &:hover {
          background-color: rgba(0,0,0,0.03);
        }
				&:nth-of-type(odd) {
					background-color: ${props => props.theme.tableRowBgColor};
				}
				.react-data-table--cell-content {
					z-index: 2;
					text-align: center;
					width: 100%;
					>a {
						font-weight: 600;
						color: ${props => props.theme.borderBlack};
						&:hover {
							text-decoration: none;
						}
					}
					p, span {
						font-size: 11px;
					}
				}
				>div {
					&:first-child {
						border-left: none;
					}
					&:nth-child(2) {
						.react-data-table--cell-content {
							z-index: 0;
						}
					}
				}
				&:last-child {
					>div {
						&:first-child {
							border-radius: 0 0 0 15px;
						}
					}
					>div {
						&:last-child {
							border-radius: 0 0 15px 0 ;
						}
					}
				}
			}
		}
    .rdt_TableFooter {
			span, div, p, select {
				font-size: 14px;
			}
		}
  }
  .rdt_Pagination {
    font-size: 16px;
  }
`;

export default StyledTableData;
