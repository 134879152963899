import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';

function Links(props) {
    let arrProps = {...props};
    const useStyles = makeStyles(() =>
        createStyles({
            root: {
            '& > *': {
                color: props.color,
                fontSize: `${props.size}px`
            }}
        })
    );
    const arrDefauft = ["initial","inherit","primary","secondary","textPrimary","textSecondary","error"];
    const isDefault = arrDefauft.find(item => item === props.color);
    if(!isDefault){
        delete arrProps["color"];
    }
    const classes = useStyles();

    return <span className={classes.root}>
                <Link {...arrProps}/>
            </span>
    
}

Links.propTypes = {
    children: PropTypes.node,
    size: PropTypes.number,
    style: PropTypes.object
};

export default Links;