import React from 'react';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';

function FloatingAction(props) {

  const { href, size, disabled, bgColorStyle, colorStyle } = props;

  const useStyles = makeStyles({
    root: {
      '&.MuiFab-root': {
        backgroundColor: bgColorStyle,
        color: colorStyle
      }
    },
  });

  const classes = useStyles();

  return (
    <div>
      <Fab
        className={classes.root}
        href={href}
        disabled={disabled}
        size={size}
      >
        {props.children}
      </Fab>
    </div>
  )
}


FloatingAction.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  disabled: PropTypes.bool,
  bgColorStyle: PropTypes.string,
  colorStyle: PropTypes.string,
}

export default FloatingAction;