import React from 'react';
import PropTypes from 'prop-types';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import { makeStyles } from '@material-ui/core/styles';
import { BottomNavigationAction } from '@material-ui/core';

function BottomNavi(props) {
    const [value, setValue] = React.useState(0);
    let { listItem, bgNav, tabColor, tabActiveColor, getTabIndex } = props;
    const useStyles = makeStyles({
        root: {
            width: '100%',
            background: bgNav,
        },
        selected: {
            '&.Mui-selected': {
                color: tabActiveColor
            },
            color: tabColor
        }
    });

    const classes = useStyles();

    return (
        <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
                getTabIndex(newValue);
            }}
            showLabels
            className={classes.root}
        >
            {listItem && listItem.map((v, k) => {
                return <BottomNavigationAction
                    key={k}
                    label={v.label}
                    icon={v.icon}
                    className={classes.selected} />
            })}
        </BottomNavigation>
    );
}

BottomNavi.propTypes = {
    bgNav: PropTypes.string,//background navigation
    tabColor: PropTypes.string,// color icon and label tab
    tabActiveColor: PropTypes.string,// color icon and label active tab
    listItem: PropTypes.array,// list item
    getTabIndex: PropTypes.func,// get tab index
    loading: PropTypes.bool,
};

export default BottomNavi;