import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import Paper from "../Paper/index";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 320,
    textAlign: "left",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

function CardComponent(props) {
  const {
    avatar,
    titleheader,
    subheader,
    action,
    imageurl,
    src,
    expanded,
  } = props;
  const classes = useStyles();
  return (
    <Paper {...props} className={classes.root}>
      <CardHeader
        avatar={avatar}
        title={titleheader}
        subheader={subheader}
        action={action}
      />
      <CardMedia className={classes.media} image={imageurl} src={src} />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {props.content}
        </Typography>
      </CardContent>
      <CardActions {...props}>{props.children}</CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit {...props}>
        <CardContent>{props.showcontent}</CardContent>
      </Collapse>
    </Paper>
  );
}

CardComponent.propTypes = {
  avatar: PropTypes.node,
  titleheader: PropTypes.node, //Title Header
  subheader: PropTypes.node, //Sub Title Header
  action: PropTypes.node,
  imageurl: PropTypes.string, // background url image
  src: PropTypes.string,
  children: PropTypes.node,
  content: PropTypes.string, // Content card
  expanded: PropTypes.bool, // Content card
};

export default CardComponent;
