import React, { useState } from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import * as S from './style'
function Selects(props) {
	const {
		onValueSelect,
		listoption,
		defaultValue,
		className,
		noValue,
		selectProps,
		selectID,
		txtLable,
		classForm,
	} = props;
	const [valueOption, setValueOption] = useState(defaultValue);
	const option =
		listoption &&
		listoption.map((item, index) => {
			return (
				<MenuItem key={index} value={item.value}>
					{item.title}
				</MenuItem>
			);
		});
	return (
		<S.FormControls className={classForm}>
			<InputLabel htmlFor={selectID}>{txtLable}</InputLabel>
			<Select
				id={selectID}
				className={className}
				value={valueOption}
				onChange={(e) => {
					setValueOption(e.target.value);
					onValueSelect && onValueSelect(e.target.value);
				}}
				{...selectProps}
			>
				{noValue &&
					<MenuItem value={noValue} disabled>
						<em>{noValue}</em>
					</MenuItem>
				}
				{option}
			</Select>
		</S.FormControls>
	);
}

Selects.propTypes = {
	listoption: PropTypes.array,
	onValueSelect: PropTypes.func,
	defaultValue: PropTypes.string || PropTypes.number,
	noValue: PropTypes.string || PropTypes.number,
	selectID: PropTypes.string,
	classForm: PropTypes.string,
	className: PropTypes.string,
	txtLable: PropTypes.string,
	selectProps: PropTypes.object,
};

export default Selects;
