import React from 'react';
import PropTypes from 'prop-types';
import { Chip, createStyles, makeStyles } from '@material-ui/core';


function Chips(props) {

    const { bgcolor, textcolor } = props;

    const useStyles = makeStyles((theme) =>
        createStyles({
            custom: {
                '&.MuiChip-root': {
                    background: bgcolor || 'primary',
                    color: textcolor || 'primary',
                    margin: theme.spacing(0.5),
                },
                '&.MuiChip-outlined': {
                    border: `1px solid ${textcolor}`
                },
                '&.MuiChip-deleteIcon': {
                    color: textcolor
                }
            }
        }),
    );

    const classes = useStyles();

    return <Chip {...props} className={classes.custom} />
}

Chips.propTypes = {
    bgcolor: PropTypes.string,
    textcolor: PropTypes.string,
    loading: PropTypes.bool,
};

export default Chips;