import React from 'react';
import  { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";

import * as S from './style';
import { useStyles } from "./style";
import {
    Card,
    CardContent,

    Link
} from "@material-ui/core";
import {  useHistory } from "react-router-dom";

import category2 from 'assets/img/product/category-2.png';


const movebgAnimation = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 4000px 0;
  }
`


function CardProduct(props: any): React.ReactElement {
    const { data } = props
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation("common");
    const { i18n } = useTranslation('common');
    const language = i18n.language 

    return (
        <>
            {
                data?.map((item: any, index: any) => {
                    return <div className={classes.item} key={index}>
                        <Card variant="outlined" className={classes.wrapperCard}>
                            <img src={item?.thumbnail??category2} alt='product' />
                            <CardContent className={"content"}>
                                <p className={"title-card"}>
                                    {language === 'en' ? item?.nameEN : item?.name}
                                </p>
                                <Link
                                    onClick={() => history.push(`/product-detail/${item._id}`, { id: item._id })}
                                    // href={item?.link ?? '#'} 
                                    underline="none" 
                                    className={"see-more"}


                                >
                                    {t('button.view_more')}
                                </Link>

                            </CardContent>
                        </Card>
                    </div>
                })
            }
        </>

    );
}



export default (CardProduct);