import styled from 'styled-components';

export const MapWrap = styled.div`
	position: relative;
	overflow: hidden;
	margin: auto;
`;

export const Map = styled.div`
	margin: auto;
	width: 100%;
	height: 100%;
`;

export const Zoom = styled.div`
	position: absolute;
	bottom: 50px;
	right: 10px;
	overflow: hidden;
	z-index: 1;
	background-color: #f5f5f5;
	width: auto;
	height: auto;
	border: 1px solid #919191;
	border-radius: 5px;
	&>div:first-child{
		border-bottom: 1px solid #919191;
	}
`;
