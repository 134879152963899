import React from 'react';
import PropTypes from "prop-types";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from 'components';

function AutocompleteComponent(props) {

  const { options, renderOption, getOptionLabel } = props;

  return (
    <Autocomplete
      options={options}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField {...params}{...props} />
      )}
      {...props}
    />
  )
}

AutocompleteComponent.propTypes = {
  options: PropTypes.array,
  renderInput: PropTypes.func,
  renderOption: PropTypes.func,
  getOptionLabel: PropTypes.func,
  filterOptions: PropTypes.func,
}

export default AutocompleteComponent;