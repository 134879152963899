import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { MemoryRouter, Route } from 'react-router';
import { Link } from 'react-router-dom';
import PaginationItem from '@material-ui/lab/PaginationItem';

function Paginations(props) {
	const { paginationProps, count, url, type, horizontal,color,brgColor } = props;

	const exchange = (e) => {
		switch (e) {
			case 'center':
				return 'center';
			case 'left':
				return 'flex-start';
			case 'right':
				return 'flex-end';
			default:
		}
	};

	const stylePagination = {
		root: {},
		ul: {
			justifyContent: exchange(horizontal),
			'& .MuiPaginationItem-page.Mui-selected': {
				backgroundColor: brgColor,
				color: color
			},
			'& .MuiPaginationItem-page.Mui-selected:hover': {
				filter: 'brightness(150%)',
				backgroundColor: brgColor,
				color: color
			},
		},
	};
	const StylePagination = withStyles(stylePagination)(Pagination);
	if (type && type === 'Router') {
		return (
			<MemoryRouter initialEntries={[`/${url}`]} initialIndex={0}>
				<Route>
					{({ location }) => {
						const query = new URLSearchParams(location.search);
						const page = parseInt(query.get('page') || '1', count);
						return (
							<StylePagination
								page={page}
								count={count}
								{...paginationProps}
								renderItem={(item) => (
									<PaginationItem
										component={Link}
										to={`/${url}${item.page === 1 ? '' : `?page=${item.page}`}`}
										{...item}
									/>
								)}
							/>
						);
					}}
				</Route>
			</MemoryRouter>
		);
	}
	return <StylePagination {...paginationProps}></StylePagination>;
}

Paginations.propTypes = {
	paginationProps: PropTypes.object,
	count: PropTypes.number,
	url: PropTypes.string,
	type: PropTypes.string,
	horizontal: PropTypes.string,
	backgroundColor: PropTypes.string,
	color: PropTypes.string,
};

export default Paginations;
