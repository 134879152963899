import Endpoints from "constants/endpoints";
import { mainAxios, mainAxiosToken } from "libs/axios";

export async function postContact(formData ?:any) {
  return await mainAxios
    .post(Endpoints.POST_CONTACT,formData)
    .then((response: any) => {
      // console.log("response", response);
      return response;
    })
    // 응답(실패)
    .catch(() => {
      return null;
    });
}
