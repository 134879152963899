import React from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

function Timelines(props) {
	const { timelineProps, listTimelines, variant, oppositeColor,sizeDot } = props;
	const styleTimeline = {
		root: {
			textAlign: 'left',
		},
	};
	const styleTimelineOppositeContent = {
		root: {
			color: oppositeColor,
		},
	};
	const StyleTimeline = withStyles(styleTimeline)(Timeline);
	const StyleTimelineOppositeContent = withStyles(styleTimelineOppositeContent)(TimelineOppositeContent);

	if (variant === 'outlined') {
		const timelineItem =
			listTimelines &&
			listTimelines.map((item, index) => {
				const styleTimelineDot = {
					root: {
						borderColor: item.color,
						padding:sizeDot
					},
				};

				const StyleTimelineDot = withStyles(styleTimelineDot)(TimelineDot);

				return (
					<TimelineItem key={index}>
						<StyleTimelineOppositeContent>
							<Typography>{item.opposite}</Typography>
						</StyleTimelineOppositeContent>
						<TimelineSeparator>
							<StyleTimelineDot variant={variant}>{item.icon && item.icon}</StyleTimelineDot>
							<TimelineConnector />
						</TimelineSeparator>
						<TimelineContent>{item.title}</TimelineContent>
					</TimelineItem>
				);
			});
		return <StyleTimeline {...timelineProps}>{timelineItem}</StyleTimeline>;
	}
	const timelineItem =
		listTimelines &&
		listTimelines.map((item, index) => {
			const styleTimelineDot = {
				root: {
					backgroundColor: item.color,
					padding:sizeDot/2
				},
			};
			const StyleTimelineDot = withStyles(styleTimelineDot)(TimelineDot);

			return (
				<TimelineItem key={index}>
					<StyleTimelineOppositeContent>
						<Typography>{item && item.opposite}</Typography>
					</StyleTimelineOppositeContent>
					<TimelineSeparator>
						<StyleTimelineDot>{item && item.icon && item.icon}</StyleTimelineDot>
						<TimelineConnector />
					</TimelineSeparator>
					<TimelineContent>{item && item.title}</TimelineContent>
				</TimelineItem>
			);
		});
	return <StyleTimeline {...timelineProps}>{timelineItem}</StyleTimeline>;
}

Timelines.propTypes = {
	timelineProps: PropTypes.object,
	listTimelines: PropTypes.array,
	variant: PropTypes.string,
	oppositeColor: PropTypes.string,
	title: PropTypes.any,
	icon: PropTypes.object,
	color: PropTypes.string,
	sizeDot: PropTypes.number
};

export default Timelines;
