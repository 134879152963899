import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";
import * as S from './style';
import { useStyles, CssTextField } from "./style";
import {

    Button,

} from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import {
    CardBgr
} from "components";
import decoration from 'assets/img/heading-decoration-bg.png';
import viewMore from 'assets/img/view-more.png';
import viewMoreGrey from 'assets/img/view-more-grey.png';
import { postContact } from 'apis/Contact';



const movebgAnimation = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 4000px 0;
  }
`
const Decoration = styled.div`
    &:before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-image: url(${decoration});
        background-repeat: repeat;
        animation: ${movebgAnimation} 0s linear infinite;
    }
`


function Contact(props: any): React.ReactElement {
    const { data } = props
    const { t } = useTranslation("common");

    const classes = useStyles();
    const history = useHistory();
    const nameRef = useRef(null)
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [content, setContent] = useState('')
    useEffect(() => {
        // console.log('nameRef :>> ', nameRef);
    }, [nameRef])

    const handleSubmit = async () => {
        const data = {
            name,
            phone,
            content
        }
        let bodyFormData = new FormData();
        bodyFormData.append('name', name);
        bodyFormData.append('phone', phone);
        bodyFormData.append('content', content);
        const respon = await postContact(data)
        console.log(`respon`, respon)
    }
    return (
        <>
            <form className={classes.rootContact}>
                <div className={classes.row}>
                    <div
                        className={classes.col}>
                        <CssTextField
                            className={classes.input}
                            label={t("text.name_contac")}
                            variant="outlined"
                            id="custom-css-outlined-input"
                            // ref={nameRef}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div
                        className={classes.col}>
                        <CssTextField
                            className={classes.input}
                            label={t("text.phone_contac")}
                            variant="outlined"
                            id="custom-css-outlined-input"
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>

                </div>
                <div className={classes.row}>
                    <div
                        className={classes.col}>
                        <CssTextField
                            className={classes.input}
                            label={t("text.content_contac")}
                            variant="outlined"
                            id="custom-css-outlined-input"
                            multiline
                            rows={4}
                            onChange={(e) => setContent(e.target.value)}
                        />
                    </div>
                </div>
                <div className={classes.row}>
                    <div
                        className={classes.col}>
                        <Button
                            className={classes.buttonSend}
                            onClick={() => handleSubmit()}
                        >
                           {t("button.send")}
                        </Button>
                    </div>
                </div>
            </form>

        </>

    );
}



export default (Contact);