import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CustomRadio from '@material-ui/core/Radio';

function Radio(props) {

  const { label, value, checked } = props;

  return (
    <FormControlLabel
      control={
        <CustomRadio
          checked={checked}
          value={value}
          {...props}
        />
      }
      label={label}
    />
  )
}

Radio.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
}

export default Radio;