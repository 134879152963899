import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "20px 0",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    alignItems: "center",
    position: "absolute",
    zIndex: 9,
    background: "#fff",
    top: "-70px",
    left: 0,
    border: "2px solid #0026f7",
    borderRadius: "20px",
    width: "100%",
    "@media (max-width: 767px)": {
      padding: "20px 0",
      flexDirection: "column",
      position: "static",
      border: "0",
    },
  },
  item: {
    marginBottom: 30,
    "& .title": {
      fontSize: 44,
      color: "#16a455",
      fontWeight: "bold",
      lineHeight: "57px",
      "@media (max-width: 767px)": {
      lineHeight: "35px",
      fontSize: 30,
      },
    },
    "& .des": {
      fontSize: 19,
      color: "#131f26",
      lineHeight: "30px",
      "@media (max-width: 767px)": {
      lineHeight: "19px",
      fontSize: 16,
      },
    },
    "& .note": {
      fontSize: 17,
      color: "#979797",
      lineHeight: "27px",
      "@media (max-width: 767px)": {
        fontSize: 14,
        lineHeight: "16px",

      },
    },
  },
}));

function ListInfo(props) {
  const { placement, title } = props;
  const classes = useStyles();
  const { t } = useTranslation("common");

  const items = [
    {
      title: "650",
      des: t("text.info"),
      note: t("text.desInfo"),
    },
    {
      title: "10.000+",
      des: t("text.info2"),
      note: t("text.desInfo2"),
    },
    {
      title: "17",
      des: t("text.info3"),
      note: t("text.desInfo3"),
    },
    {
      title: "110kV",
      des: t("text.info4"),
      note: t("text.desInfo4"),
    },
  ];
  return (
    <div 
    style={{
      backgroundColor: "rgb(241, 242, 236)",
    }}
    >
    <Container maxWidth="md" 
    style={{
      position: 'relative',
      minHeight: 120,
    }}
    >
      <div className={classes.wrapper}>
        {items.map((el, index) => (
          <div className={classes.item}>
            <div className="title">{el.title}</div>
            <div className="des">{el.des}</div>
            <div className="note">{el.note}</div>
          </div>
        ))}
      </div>
    </Container>
    </div>
  );
}

export default ListInfo;
