import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  container: {
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 250,
  },
}));

function DateTimePicker(props) {
  const { label, defaultValue, value, onDateChange } = props;

  const [date, setDate] = useState();

  const classes = useStyles();

  useEffect(() => {
    setDate(date);
  }, [date]);

  const customDateTimePicker = () => {
    switch (props.type) {
      case "datetime-local":
        return (
          <form className={classes.container} noValidate>
            <TextField
              id="datetime-local"
              label={label}
              value={value}
              type="datetime-local"
              defaultValue={defaultValue}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(date) => {
                setDate(date);
                onDateChange && onDateChange(date);
              }}
            />
          </form>
        );
      case "date":
        return (
          <form className={classes.container} noValidate>
            <TextField
              id="date"
              label={label}
              value={value}
              type="date"
              defaultValue={defaultValue}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(date) => {
                setDate(date);
                onDateChange && onDateChange(date);
              }}
            />
          </form>
        );
      case "time":
        return (
          <form className={classes.container} noValidate>
            <TextField
              id="time"
              label="Alarm clock"
              type="time"
              value={value}
              defaultValue={defaultValue}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              onChange={(date) => {
                setDate(date);
                onDateChange && onDateChange(date);
              }}
            />
          </form>
        );
      default:
        return <div></div>;
    }
  };

  return customDateTimePicker();
}

DateTimePicker.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  onDateChange: PropTypes.func,
};

export default DateTimePicker;
