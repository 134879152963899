import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import * as S from './style';
import { useStyles } from "./style";
import { Container, Link, CardActions, CardContent, Button, Typography } from '@material-ui/core';
import {
    CardBgr
} from "components";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import NewsTop from "./NewsTop";
import CardProduct from "./CardProduct";
import Contact from "./Contact";
import Introduce from "./Introduce";
import Document from "./Document";

import decoration from 'assets/img/heading-decoration-bg.png';
import viewMore from 'assets/img/view-more.png';
import viewMoreGrey from 'assets/img/view-more-grey.png';



const movebgAnimation = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 4000px 0;
  }
`
const Decoration = styled.div`
    &:before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-image: url(${decoration});
        background-repeat: repeat;
        animation: ${movebgAnimation} 0s linear infinite;
    }
`



function TopList(props: any): React.ReactElement {
    const { title, description, background, dataProject,dataNews, dataTitle, dataNewsTop, dataPartner, dataProduct, type, label,colorDes } = props
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation("common");
    const { i18n } = useTranslation('common');
    const language = i18n.language 


    const renderContent = () => {
        if (dataNewsTop && dataNewsTop.length > 0) {
            return <NewsTop data={dataNewsTop} />

        }

        if (dataPartner && dataPartner.length > 0) {
            return <div className={`${classes.row} ${classes.wrapperItem}`}>
                {
                    dataPartner.map((el: any, i: any) =>
                        <div className={classes.cellItem} key={i}>
                            <span className="helper"></span>
                            <img src={el?.img ?? ''} alt='top-new' />
                        </div>
                    )
                }
            </div>
        }

        if (dataProduct && dataProduct.length > 0) {
            return <CardProduct data={dataProduct} />
        }
        if (type === 'contact') {
            return <Contact />
        }
        if (type === 'introduce') {
            return <Introduce />
        }
        if (type === 'document') {
            return <Document />
        }
    }
    return (
        <div className={classes.wrapper} style={{ background: `${background ? background : '#fff'}` }}>
            <Container>
                <div className={classes.header}>
                    <div className={classes.title}>
                        {label ? label : title}
                    </div>
                    <Decoration className={`${classes.headingDecoration} decoration`}>

                    </Decoration>
                    <div className={classes.headingText} style={{color: colorDes}}>
                        {description}
                    </div>
                </div>          

                <div className={classes.listItem}>
                    {
                        dataProject?.map((item: any, index: any) => {
                            const title = language === 'en' ? item?.nameEN : item?.name
                            const location = type ==='news' ? '/news-detail/' : '/project-detail/'
                            return <div className={classNames(classes.item,classes.col_md_4)} key={index}>
                                <Link
                                    onClick={() => history.push(`${location}${item._id}`, { id: item._id, data: item })}
                                    // href={item?.link ?? '#'}
                                    underline="none" >
                                    <CardBgr
                                        imageurl={item?.thumbnail ?? ''}
                                        title={title}
                                    />
                                </Link>
                            </div>
                        })
                    }
                    {
                        dataNews?.map((item: any, index: any) => {
                            const title = language === 'en' ? item?.titleEN : item?.title
                            const location = type ==='news' ? '/news-detail/' : '/project-detail/'
                            return <div className={classNames(classes.item,classes.col_md_4)} key={index}>
                                <Link
                                    onClick={() => history.push(`${location}${item._id}`, { id: item._id, data: item })}
                                    // href={item?.link ?? '#'}
                                    underline="none" >
                                    <CardBgr
                                        imageurl={item?.thumbnail ?? ''}
                                        title={title}
                                    />
                                </Link>
                            </div>
                        })
                    }
                    {
                        dataTitle?.map((item: any, index: any) => {
                            return <div className={classes.item} key={index}>
                                <Typography variant="h5" component="h3" className={classes.itemTitle}>
                                    {item.title}
                                </Typography>
                                <Typography variant="body1" component="h2">
                                    {item.description}
                                </Typography>
                            </div>
                        })
                    }
                    {renderContent()}
                </div>

            </Container>
        </div>

    );
}



export default (TopList);