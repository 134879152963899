import React from 'react';
import PropTypes from 'prop-types';
import * as S from './style';


function Input(props) {
    const { planceHolder, custom, onChange } = props;
    return <S.TextFields
        {...props}
        custom={custom}
        label={planceHolder}
        onChange={onChange} />
}

Input.propTypes = {
    is: PropTypes.any,
    onChange: PropTypes.func,
    planceHolder: PropTypes.string,
    loading: PropTypes.bool,
};

export default Input;