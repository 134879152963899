// const menus = [
//   {
//     label: "Giới thiệu",
//     link: "/introduce",
//   },
//   {
//     label: "Sản phẩm thương mại",
//     link: "/product",
//     item: [
//       // {
//       //   label: "Máy cao thế",
//       //   link: "/",
//       // },
//     ],
//   },
//   {
//     label: "Dự án thực hiện",
//     link: "/project",
//   },
//   {
//     label: "Tin tức",
//     link: "/news",
//     item: [
//       {
//         label: "Tin nội bộ",
//         link: "/news",
//         idItem: 1,
//       },
//       {
//         label: "Tin chuyên nghành",
//         link: "/news",
//         idItem: 2,
//       },
//       {
//         label: "Tin tuyển dụng",
//         link: "/news",
//         idItem: 3,
//       },
//     ],
//   },
//   {
//     label: "Tài liệu",
//     link: "/document",
//   },
//   {
//     label: "Liên hệ",
//     link: "/contact",
//   },
// ];

const menus = (t?: any) => {
  const data = [
    {
      label: t('sidebar.introduce'),
      link: "/introduce",
    },
    {
      label: t('sidebar.product'),
      link: "/product",
      // item: [
      //   // {
      //   //   label: "Máy cao thế",
      //   //   link: "/",
      //   // },
      // ],
    },
    {
      label: t('sidebar.project'),
      link: "/project",
    },
    {
      label: t('sidebar.news'),
      link: "/news",
      item: [
        {
          label: t('sidebar.news1'),
          link: "/news",
          idItem: 1,
        },
        {
          label: t('sidebar.news2'),
          link: "/news",
          idItem: 2,
        },
        {
          label: t('sidebar.news3'),
          link: "/news",
          idItem: 3,
        },
      ],
    },
    {
      label: t('sidebar.document'),
      link: "/document",
    },
    {
      label: t('sidebar.contact'),
      link: "/contact",
    },
  ];
  return data;
};
export default menus;
