import React, {useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import * as S from "./style";

import { AutoRotatingCarousel, Slide } from 'material-auto-rotating-carousel'

const defaultData=[
 {
  media: 'http://www.icons101.com/icon_png/size_256/id_79394/youtube.png',
  mediaBackgroundStyle: { backgroundColor: 'red' },
  style: { backgroundColor: 'blue' },
  title: 'This is a very cool feature',
  subtitle: 'Just using this will blow your mind.'
 },
 {
  media: 'http://www.icons101.com/icon_png/size_256/id_80975/GoogleInbox.png',
  mediaBackgroundStyle: { backgroundColor: 'gray' },
  style: { backgroundColor: 'blue' },
  title: 'Ever wanted to be popular?',
  subtitle: 'Well just mix two colors and your are good to go!'
 },
 {
  media: 'http://www.icons101.com/icon_png/size_256/id_76704/Google_Settings.png',
  mediaBackgroundStyle: { backgroundColor: 'green' },
  style: { backgroundColor: 'green' },
  title: 'May the force be with you',
  subtitle: 'The Force is a metaphysical and ubiquitous power in the Star Wars fictional universe.'
 }
];

function Carousel(props) {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(defaultData);

    useEffect(() =>{
      if(props.propsValues){
        setData(props.propsValues);
      }

      if(props.open){
        setOpen(props.open);
      }
    }, [props]);

    const onStartFunc=()=>{
      // setOpen(false);
      if(props.onStart){
        props.onStart()
      }
    }

    const onCloseFunc=()=>{
      setOpen(false);
      if(props.onClose){
        props.onClose()
      }
    }

    return <S.Wrapper id="carousel" className="carousel-wrapper" style={{ position: 'relative', width: '100%' }}>
    <AutoRotatingCarousel
      {...props}
      open={open}
      onClose={()=> onCloseFunc()}
      onStart={()=> onStartFunc()}
      style={{ position: 'absolute' }}
    >
      {
        data.map((item, index) => (
          <Slide
            key={index}
            media={<img src={item.media} alt="carousel"/>}
            mediaBackgroundStyle={item.mediaBackgroundStyle}
            style={item.style}
            title={item.title}
            subtitle={item.subtitle}
          />
        ))
      }
    </AutoRotatingCarousel>
  </S.Wrapper>
}

Carousel.propTypes = {
  autoplay: PropTypes.bool,
  interval: PropTypes.number,
  label: PropTypes.string,
  landscape : PropTypes.bool,
  mobile: PropTypes.bool,
  open: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  propsValues: PropTypes.array
};

export default Carousel;