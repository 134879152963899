import styled, { keyframes } from "styled-components";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

export const movebg = keyframes`
  from {
    backgroundposition: "0 0";
  }
  to {
    backgroundposition: "4000px 0";
  }
`;

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "80px 0",
    maxWidth: "100%",
    "@media (max-width: 767px)": {
      padding: "40px 0",
    },
  },
  wrapperCard: {
    padding: "40px",
    maxWidth: "100%",
    fontWeight: "bold",
    fontSize: 16,
    color: "#1b2c38",
    border: "3px solid transparent",
    "& img": {
      height: 195,
    },
    "& .title-card": {
      marginBottom: 16,
      minHeight: 42,
    },
    "& .see-more": {
      backgroundColor: "#1b2c38",
      color: "#fff",
      position: "relative",
      cursor: "pointer",
      textDecoration: "none",
      borderRadius: "50px",
      // padding: "20px 40px",
      fontSize: "14px",
      textAlign: "center",
      lineHeight: 1,
      fontWeight: 700,
      outline: "none",
      border: "2px solid",
      maxWidth: 150,
      width: "100%",
      minHeight: 50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: 'auto',
      // "@media (max-width: 767px)": {
      //   maxWidth: "100%",
      // },
    },
    "&:hover": {
      borderColor: "#58ef9b",
    },
  },
  wrapperIntroduce: {
    maxWidth: "100%",
    width: "100%",
    textAlign: "left",
    "&>div": {
      marginBottom: 5,
    },
    "& .category": {
      marginTop: 0,
      marginBottom: 0,
      paddingTop: 45,
      paddingBottom: 45,
    },
    "& .category-title": {
      fontSize: 38,
      fontWeight: "bold",
      color: "#105EA8",
      textAlign: "center",
    },
    "& .category-body": {
      margin: "15px 0",
      '& p':{
        textIndent: 30,
      },
      '& .intro-img':{
        maxWidth: 900,
      }
      
    },
    "& .row": {
      display: "flex",
    },
    "& .col": {
      flex: 1,
      flexBasis: 0,
      flexGrow: 1,
      maxWidth: "100%",
      textAlign: 'center'
    },
    "& .tnsm": {
      position: "relative",
      zIndex: 1,
      height: 686,
      overflow: "hidden",
    },
    "& .tnsm img": {
      position: "absolute",
      zIndex: 1,
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    "& .tnsm-core": {
      width: 600,
      height: 600,
      color: "#fff",
      position: "absolute",
      zIndex: 1,
      top: "50%",
      transform: "translateY(-50%)",
      right: "calc(-600px/2)",
      borderRadius: "50%",
      paddingRight: "calc(600px/2 + 15px)",
      paddingLeft: 45,
      textAlign: "right",
      display: "flex",
      flexWrap: "wrap",
      alignContent: "center",
      opacity: 0.9,
    },
    "& .tnsm-core-l": {
      right: "auto",
      left: "calc(-600px/2)",
      paddingLeft: "calc(600px/2 + 15px)",
      paddingRight: "45px",
      textAlign: "left",
    },
    "& .bg-main-color-2": {
      backgroundColor: "#F68B3D",
    },
    "& .bg-main-color-1": {
      backgroundColor: "#105EA8",
    },
    "& .tnsm-title": {
      fontSize: 38,
      marginBottom: 10,
      fontWeight: "bold",
      width: "100%",
    },
    "& .tnsm-body": {
      fontSize: 15,
    },
  },
  header: {
    margin: "auto",
    marginBottom: 48,
    maxWidth: "70%",
    "&:hover": {
      "& .decoration:before": {
        animationDuration: "50s",
      },
    },
    "@media (max-width: 767px)": {
      maxWidth: "100%",
    },
  },
  title: {
    fontWeight: "bold",
    fontSize: "calc(1.3875rem + 0.5vw)",
    color: "#1b2c38",
    textTransform: "uppercase",
  },
  mb30: {
    marginBottom: 20,
  },
  headingDecoration: {
    marginTop: 20,
    width: 170,
    height: 2,
    maxWidth: "100%",
    position: "relative",
    display: "inline-block",
    // "&:before": {
    //     content: "''",
    //     display: 'block',
    //     position: 'absolute',
    //     width: '100%',
    //     height: '100%',
    //     left: 0,
    //     top: 0,
    //     backgroundImage: `url(${decoration})`,
    //     backgroundRepeat: 'repeat',
    // }
  },
  headingText: {
    color: "#979797",
    fontSize: 19,
    marginTop: 15,
  },
  listItem: {
    display: "flex",
    flexWrap: "wrap",
    "@media (max-width: 767px)": {
      // flexDirection: 'column'
    },
  },
  item: {
    flex: '1 1 33.33%',
    maxWidth: "33%",
    width: "33%",
    padding: "0 15px",
    marginBottom: 10,
    "@media (min-width: 768px) and (max-width: 1023px)": {
      maxWidth: "50%",
      flex: "1 1 50%",
    },
    "@media (max-width: 767px)": {
      maxWidth: "100%",
      flex: "1 1 100%",
    },
  },
  itemTitle: {
    fontWeight: "bold",
    marginBottom: 24,
  },
  row: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    "@media (max-width: 767px)": {
      flexDirection: "column",
      alignContent: "center",
    },
  },
  col: {
    flex: 1,
    maxWidth: "100%",
    width: "100%",
    padding: "0 15px",
    "& .hot-news-sub-title": {
      fontSize: 23,
      fontWeight: "bold",
    },
  },
  col_md_4: {
    // padding: "0 15px",
    "@media (min-width: 768px)": {
      flex: "0 0 33.33333%",
      maxWidth: "33.33333%",
    },
  },
  imgNews: {
    width: "100%",
    marginBottom: 24,
  },
  linkNews: {
    paddingLeft: 8,
    width: "calc(100% - 13px)",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    verticalAlign: "middle",
    fontSize: 16,
    fontWeight: "bold",
    color: "#1b2c38",
    display: "inline-block",
    cursor: "pointer",
    "&:hover": {
      color: "#27c7cd",
    },
  },
  itemNews: {
    padding: "16px 0",
    borderTop: "1px solid #707070",
    textAlign: "left",
    position: "relative",
    "&.bd-top0": {
      borderTop: 0,
    },
    "& .view-more-link": {
      position: "absolute",
      right: 0,
      fontSize: 15,
      fontWeight: 500,
      color: "#16a455",
      top: 24,
      "&:hover": {
        color: "#27c7cd",
      },
    },
  },
  wrapperItem: {
    backgroundColor: "#fff",
    borderRadius: 10,
    padding: "32px 24px",
    width: "100%",
  },
  cellItem: {
    width: "20%",
    height: 172,
    textAlign: "center",
    "& .helper": {
      display: "inline-block",
      height: "100%",
      verticalAlign: "middle",
    },
  },
  rootContact: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  input: {
    width: "100%",
    marginBottom: 20,
  },
  buttonSend: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#27c7cd",
    color: "#ffffff",
    borderRadius: "50px",
    fontSize: "14px",
    textAlign: "center",
    lineHeight: 1,
    fontWeight: 700,
    textTransform: "inherit",
    padding: "16px 32px",
    width: "100%",
    "&:hover": {
      backgroundColor: "rgb(0 0 0 / 59%)",
    },
  },
}));

export const CssTextField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      borderRadius: 20,
    },
    "& label.Mui-focused": {
      color: "#27c7cd",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#27c7cd",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        //   borderColor: 'red',
      },
      "&:hover fieldset": {
        //   borderColor: 'yellow',
      },
      "&.Mui-focused fieldset": {
        borderColor: "#27c7cd",
      },
    },
  },
})(TextField);
