import React, { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";
import * as S from './style';
import { useStyles, CssTextField } from "./style";
import {
    CardMedia,
    Card,
    CardActions,
    CardContent,
    Button,
    Typography,
    Link
} from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import {
    CardBgr
} from "components";
import decoration from 'assets/img/heading-decoration-bg.png';
import viewMore from 'assets/img/view-more.png';
import viewMoreGrey from 'assets/img/view-more-grey.png';
import { getDocuments } from 'apis/Documents';



const movebgAnimation = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 4000px 0;
  }
`
const Wrapper = styled.div`
    width: 100%;
    max-width: 100%;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`

const Col = styled.div`
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
`

const Title = styled.div`
    color: #16a455;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 10px;
`

const ListItem = styled.div`
    margin-bottom: 30px;
`

const Item = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #c2c2c2;
    align-items: center;
    padding-bottom: 13px;
    font-size: 16px;
    margin-top: 10px;
`

const ItemTitle = styled.div`
    
`

const ItemLink = styled.div`
    background-color: #131f26;
    color: #fff;
    margin-left: auto;
    width: 121px;
    height: 40px;
    border-radius: 50px;
    text-align: center;
    padding-top: 8px;
    cursor: pointer;
`

const dataDocument = [
    {
        title: 'Tài liệu kỹ thuật 1',
        link: 'https://drive.google.com/open?id=0B4B-qlWf3myGX1AyRnJyRXZ0Qmc',
    }
]

function Document(props: any): React.ReactElement {
    const { data } = props
    const classes = useStyles();
    const history = useHistory();
    const nameRef = useRef(null)
    const [dataList, setDataList] = useState<any>([])
    const { t } = useTranslation("common");
    const { i18n } = useTranslation('common');
    const language = i18n.language 
    useEffect(() => {
        getDataList()
    }, [])

    const getDataList = async () =>{
        const result = await getDocuments()
        if(result.status ===200){
            setDataList(result.data)
        }
    }

    const dataProfile = dataList && dataList.filter((item: any) => item?.category === 1 )
    const dataTechnical = dataList && dataList.filter((item: any) => item?.category === 2 )
    const dataReferences = dataList && dataList.filter((item: any) => item?.category === 3 )
    return (
        <>
            <Wrapper>
                <Col>
                    <Title>
                        {t("text.document_detail")}
                    </Title>
                    <ListItem>
                        {dataProfile?.map((item:any, i:any) => {
                            console.log('item :>> ', item);
                            return <Item key={i}>
                                <ItemTitle>
                                    {
                                        language === 'en' ? item?.nameEN : item?.name
                                    }
                                </ItemTitle>
                                <ItemLink onClick={()=>window.open(item.url)} >
                                    {t('text.download')}
                                </ItemLink>

                            </Item>
                        })
                        }
                    </ListItem>
                </Col>
                <Col>
                    <Title>
                        {t("text.document_detail2")}
                    </Title>
                    <ListItem>
                        {dataTechnical?.map((item:any, i:any) => {
                            return <Item key={i}>
                                <ItemTitle>
                                    {item?.name}
                                </ItemTitle>
                                <ItemLink onClick={()=>window.open(item.url)} >
                                {t('text.download')}

                                </ItemLink>

                            </Item>
                        })
                        }
                    </ListItem>
                </Col>
                <Col>
                    <Title>
                        {t("text.document_detail3")}
                    </Title>
                    <ListItem>
                        {dataTechnical?.map((item:any, i:any) => {
                            return <Item key={i}>
                                <ItemTitle>
                                    {item?.name}
                                </ItemTitle>
                                <ItemLink onClick={()=>window.open(item.url)} >
                                {t('text.download')}

                                </ItemLink>

                            </Item>
                        })
                        }
                    </ListItem>
                </Col>
            </Wrapper>
        </>

    );
}



export default (Document);