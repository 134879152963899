/**
 *
 * SearchBox
 *
 */

import React, { memo, useState } from 'react';
import SearchWrapper, { SearchBoxIcon, RemoveIcon, Input } from './StyledSearchBox'
import styled from 'styled-components';

const StyledInput = styled(Input)`
	border-radius: 25px !important;
	width: 100%;
	display: inline-block;
	border: 1px solid #979797 !important;
	padding-left: 15px;
  padding-right: 35px;
	min-height: 35px !important;
  line-height: 20px!important;
  position: relative;
  min-width: 310px;
  background: transparent!important;
`;

function SimpleSearchBox({ onSearch, ...props }) {

  const [query, setQuery] = useState('');

  const keyMap = {
    ENTER: 13,
    DELETE: 46
  };

  const handleOnKeyDown = (evt) => {
    setQuery(evt.target.value);
    if (evt.keyCode === keyMap.ENTER) {
      onSearch && onSearch(evt.target.value);
    }
  };

  const onChange = e => {
    setQuery(e.target.value);
  }

  const onRemoveSearch = () => {
    setQuery('');
    onSearch && onSearch('');
  };

  return (
    <SearchWrapper>
      <StyledInput
        placeholder="Search"
        type="text"
        value={query}
        onKeyDown={handleOnKeyDown}
        onChange={onChange}
        {...props} />
      {!query.length && <SearchBoxIcon icon="search" onClick={onSearch} />}
      {query.length > 0 && <RemoveIcon icon="cancel" onClick={onRemoveSearch} />}
    </SearchWrapper>
  );
}



SimpleSearchBox.propTypes = {};

export default memo(SimpleSearchBox);
