import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import MenuList from "@material-ui/core/MenuList";
import ListItemIcon from "@material-ui/core/ListItemIcon";

function Menus(props) {
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const activeItem = (index) => {
    setSelectedIndex(index);
    props.onClick();
  };

  const renderMenu = () => {
    switch (props.type) {
      case "MENU":
        return (
          <Menu
            {...props}
            anchorEl={document.querySelector(`[aria-controls="${props.id}"]`)}
            keepMounted
          >
            {props.options.map((option, index) => (
              <MenuItem
                key={option.id}
                selected={props.isSelected ? index === selectedIndex : null}
                onClick={() => activeItem(index)}
              >
                {option.icon && (
                  <ListItemIcon style={{ minWidth: 35 }}>
                    {option.icon}
                  </ListItemIcon>
                )}
                {option.text}
              </MenuItem>
            ))}
          </Menu>
        );
      case "MENU_LIST":
        return (
          <Paper>
            <MenuList>
              {props.options.map((option, index) => (
                <MenuItem
                  key={option.id}
                  selected={props.isSelected ? index === selectedIndex : null}
                  onClick={() => activeItem(index)}
                >
                  {option.icon && (
                    <ListItemIcon style={{ minWidth: 35 }}>
                      {option.icon}
                    </ListItemIcon>
                  )}
                  {option.text}
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        );
      default:
        return (
          <Menu
            {...props}
            anchorEl={document.querySelector(`[aria-controls="${props.id}"]`)}
            keepMounted
          >
            {props.options.map((option, index) => (
              <MenuItem
                key={option.id}
                selected={props.isSelected ? index === selectedIndex : null}
                onClick={() => activeItem(index)}
              >
                {option.icon && (
                  <ListItemIcon style={{ minWidth: 35 }}>
                    {option.icon}
                  </ListItemIcon>
                )}
                {option.text}
              </MenuItem>
            ))}
          </Menu>
        );
    }
  };

  return renderMenu();
}

Menus.propTypes = {
  options: PropTypes.array,
  onClick: PropTypes.func,
  type: PropTypes.string,
  isSelected: PropTypes.bool,
};

export default Menus;
