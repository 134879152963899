export default {
  LOGIN_URL: "/api/v1/login",
  ME: "/api/v1/me",
  FAQ: "/api/v1/qnas/categories",
  SIGNUP_URL: "/api/v1/signup",
  ARTICLE_URL: "/api/v1/posts",
  ARTICLE_CATEGORY: "/api/v1/posts/categories",
  CHALLENGE_EVENT: "/api/v1/social​/challenge​/event",
  SOCIAL: "/api/v1/social",
  SOCIAL_CHALLENGE_LIKE: "/api/v1/social/challenge/toggle-like",
  SOCIAL_GOOD_EXAMPLE_LIKE: "/api/v1/social/good-example/toggle-like",
  CHALLENGE_CURRENT: "/api/v1/social/challenge/current",
  CHALLENGE_DELETE: "/api/v1/social/challenge/delete",
  GOOD_EXAMPLE_DELETE: "/api/v1/social/good-example/delete",
  CHALLENGE_PREVIOUS: "/api​/v1​/social​/challenge​/previous",
  GOOD_EXAMPLE: "/api​/v1​/social​/good-example​/list",
  EXPERT_URL: "/api/v1/expert",
  MYPAGE_CONSULTING: "/api/v1/consulting-room/getAll",
  PET: "/api/v1/pet",
  TEST: "/api/v1/test",
  SEND_FAQ: "/api/v1/qnas",

  SETTING_URL: "/api/v1/me/subscribe",
  FIND: "api/v1/find",
  COMPLETE_SKILL: "/api/v1/training",
  REVIEWS_URL: "/api/v1/consulting-reviews/expert",
  PROGRAM_CUSTOMIZED_URL: "/api​/v1​/customized-program​/skill-detail-recomend",
  SKILL: "api/v1/skill",
  SKILL_CATEGORY: "/api/v1/skill-category",
  TRAINING: "/api/v1/training",
  CONSULTING_REVIEWS_REVIEW_EXPERTS: "/api/v1/consulting-reviews/experts",
  CONSULTING_ROOM_PAYMENT_URL: "/api/v1/payment-consultingroom",
  DOG_SPECIES_URL: "/api/v1/dog-species",
  UPDATE_INFO: "/api/v1/me/update-info",
  CONVERT_FILE: "/api/v1/files",
  CONSULTINGROOM_URL: "/api/v1/payment-consultingroom/history",
  CONSULTING_GET_ALL_URL: "/api/v1/consulting-room/getAll",
  CONSULTING_ROOM_URL: "/api/v1/consulting-room/detail",
  CONSULTING_MINUTE_URL: "/api/v1/consulting-minute",
  UPDATE_EXPERT: "/api/v1/expert/update",
  MY_REVIEW: "/api/v1/consulting-reviews/me",
  DELETE_REVIEW: "/api/v1/consulting-reviews/delete",
  PAYMENT_CONSULTINGROM: "/api/v1/payment-consultingroom/methods",
  PAYMENT_CONSULTINGROM_UPDATE:
    "/api/v1/payment-consultingroom/create-or-update",
  PAYMENT_CONSULTINGROM_READY: "/api/v1/payment-consultingroom/ready",
  SKILL_DETAIL: "/api/v1/skill-detail",
  POSTS: "/api/v1/posts",
  SEARCH_WORD: "/api/v1/search-word",
  PRODUCT_ALL: "/api/v1/consulting-product/getAll",
  KAKAO: "/api/v1/kakao-login",
  NAVER: "/api/v1/naver-login",
  LEAVE: "/api/v1/me/leave",
  CUSTOMIZED_PROGRAM: "/api/v1/customized-program/skill-detail-recomend",
  ARTICLE_RECOMMEND: "/api/v1/customized-program/article-recomend",
  NOTIFICATION_PAYMENT: "/api/v1/payment-consultingroom/notification",
  BANNER_URL: "/api​/v1​/banner",
  DOCUMENTS_URl: "/api/v1/documents/type",
  CONSULTING_MINUTE_DETAILS: "/api/v1/consulting-minute/detail",
  CONSULTING_MINUTE_UPDATE: "/api/v1/consulting-minute/update",
  UPLOAD_FILE_URL: "/api/v1/files",
  REVIEW_EXPERT: "/api/v1/consulting-reviews/experts",

  // start add new
  LOGINSNS: "/api/v1/sns/login",
  KAKAO_OATH2: "/api/v1/sns/get-access-token-kakao",
  NAME_REGISTER: "/api/v1/user/display-name",
  CHECK_NAME_REGISTER: "/api/v1/user/display-name/check",
  NOTIFICATION: "/api/v1/acarz-notification",
  POINT_LIST: "/api/v1/point-center",
  QNA: "/api/v1/qnas/categories",
  ASK_QNA: "/api/v1/qnas/ask",
  // end add new
  BANNER: "/api/v1/banners",
  POST_CARK_TALK: "/api/v1/cartalk",
  GET_PUSH_LIST: "/api/v1/acarz-notification",
  LIST_CARK_TALK: "/api/v1/cartalk",
  LIST_CATEGORY: "/api/v1/category-cartalk",
  SETTING: "/api/v1/user/settings",
  ATTENDED: "/api/v1/point-center/attended",
  UPLOAD_FILE: "/api/v1/files",
  UPDATE_CARK_TALK: "/api/v1/cartalk/1/edit",
  INFO_ATTENDED: "/api/v1/point-center/attended-infomation",
  INFO_ATTENDED_NOt_LOGIN: "/api/v1/point-center/attended-infomation-not-login",
  USER_INFO: "/api/v1/me",


  LIST_PRODUCT: "/api/v1/public/product/getAll",
  PRODUCT_DETAIL: "/api/v1/public/product/detail",

  POST_CONTACT: "/api/v1/public/contact/add",

  LIST_PROJECT: "/api/v1/public/project/getAll",
  PROJECT_TOP: "/api/v1/public/project/getTop",
  PROJECT_DETAIL: "/api/v1/public/project/detail",

  LIST_NEWS: "/api/v1/public/post/getAll",
  NEWS_DETAIL: "/api/v1/public/post/detail",
  NEWS_TOP: "/api/v1/public/post/getTop",

  LIST_DOCUMENT: "/api/v1/public/document/getAll",
  
};
