import React, { useState } from "react";
import PropTypes from "prop-types";
import CustomCheckBox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import { CustomLabel } from "components";

function CheckBox(props) {
  const { color, defaultChecked, colorchecked, value, onChange } = props;

  const [newValue, setNewValue] = useState();

  const useStyles = makeStyles({
    root: {
      '&.Mui-checked': {
        color: `${colorchecked} !important`,
      },
    },
  });

  const classes = useStyles();

  const handleValueChange = (e) => {
    setNewValue(e.target.value);
    onChange && onChange(newValue)
  };

  return (
    <div>
      <CustomCheckBox
        value={value}
        defaultChecked={defaultChecked}
        className={classes.root}
        onChange={(e) => handleValueChange(e)}
        name="checkBox"
        color={color || "default"}
        {...props}
      />
      <CustomLabel {...props} />
    </div>
  );
}

CheckBox.propTypes = {
  handleChecked: PropTypes.func,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  colorchecked: PropTypes.string, //Color when checked
};

export default CheckBox;
