import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import {
  ArgumentAxis,
  ValueAxis,
  Chart,
  LineSeries,
  BarSeries,
  PieSeries,
  Title,
  Tooltip
} from '@devexpress/dx-react-chart-material-ui';
import { Animation,EventTracker } from '@devexpress/dx-react-chart';

function Charts(props) {
  const renderChart =()=>{
    switch (props.type){
      case 'LINE_CHART':
        return <Paper>
          <Chart {...props.chartProps}>
            <ArgumentAxis />
            <ValueAxis />
            <LineSeries {...props.valueProps} />
            {props.title && <Title text={props.title} />}
            <EventTracker />
            <Tooltip />
            <Animation />
          </Chart>
        </Paper>
      case 'BAR_CHART':
        return <Paper>
          <Chart {...props.chartProps}>
            <ArgumentAxis />
            <ValueAxis max={7} />
            <BarSeries {...props.valueProps} />
            {props.title && <Title text={props.title} />}
            <EventTracker />
            <Tooltip />
            <Animation />
          </Chart>
        </Paper>
      case 'PIE_CHART':
        return <Paper>
        <Chart {...props.chartProps}>
          <PieSeries {...props.valueProps}/>
            {props.title && <Title text={props.title} />}
            <EventTracker />
            <Tooltip />
            <Animation />
          <Animation />
        </Chart>
      </Paper>
      default:
        return <Paper>
          <Chart {...props.chartProps}>
            <ArgumentAxis />
            <ValueAxis />
            <LineSeries {...props.valueProps} />
            {props.title && <Title text={props.title} />}
            <EventTracker />
            <Tooltip />
            <Animation />
          </Chart>
        </Paper>
      }
  }

  return renderChart()
    
}

Charts.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  valueProps: PropTypes.object,
  chartProps: PropTypes.object,
  data: PropTypes.array,
  height: PropTypes.number,
  width: PropTypes.number,
  valueField: PropTypes.string,
  argumentField: PropTypes.string,
  innerRadius: PropTypes.number
};

export default Charts;