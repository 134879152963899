import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "../Paper/index";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function AccordionComponent(props) {
  const { expandicon, disabled } = props;

  const classes = useStyles();

  return (
    <Paper {...props} className={classes.root}>
      <Accordion disabled={disabled}>
        <AccordionSummary expandIcon={expandicon}>
          {props.childrenheader}
        </AccordionSummary>
        <AccordionDetails>{props.content}</AccordionDetails>
      </Accordion>
    </Paper>
  );
}

AccordionComponent.propTypes = {
  expandicon: PropTypes.node,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default AccordionComponent;
