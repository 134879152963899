import React from 'react';
import PropTypes from 'prop-types';
import { Divider, makeStyles } from '@material-ui/core';
// import * as S from './style';

function Dividers(props) {
    const { colorstyle, size } = props;
    const useStyles = makeStyles(() => ({
        root: {
            '&.MuiDivider-root': {
                background: colorstyle || 'primary',
                height: `${size}px`
            }
        },
    }));

    const classes = useStyles();

    return <Divider
        className={classes.root}
        id="standard-basic" />
}

Dividers.propTypes = {
    colorstyle: PropTypes.string,
    size: PropTypes.number,
    loading: PropTypes.bool,
};

export default Dividers;