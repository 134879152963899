import React, { Suspense, lazy } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import loading from 'assets/img/loading.svg';
import { LoadingIndicator } from "components";
import { MixLayout } from 'containers';
import { useTranslation } from "react-i18next";

//Test page
const Login = lazy(() => import('pages/Login'));
const Register = lazy(() => import('pages/Register'));
const Home = lazy(() => import('pages/Home'));
const Product = lazy(() => import('pages/Product'));
const ProductDetail = lazy(() => import('pages/ProductDetail'));
const Contact = lazy(() => import('pages/Contact'));
const News = lazy(() => import('pages/News'));
const NewsDetail = lazy(() => import('pages/NewsDetail'));
const Project = lazy(() => import('pages/Project'));
const ProjectDetail = lazy(() => import('pages/ProjectDetail'));
const Introduce = lazy(() => import('pages/Introduce'));
const Document = lazy(() => import('pages/Document'));

//Test Components UI
const Test = lazy(() => import('tests/test.ui'));


// const renderLoader = () => <div className='loading'><img className='icon-loading' src={loading} alt='loading' /></div>;
const renderLoader = () => <div className='loading'><LoadingIndicator /></div>;

function App() {
  // const { t } = useTranslation("common");
  // const { i18n } = useTranslation('common');

  return (
    <div className="App">
      <Router>
        <Switch>
          <Suspense fallback={renderLoader()}>
            <MixLayout >
              <SecurityRoute exact path="/test" component={Test} />
              <PublicRoute exact path="/" component={Home} />
              <PublicRoute path="/login" component={Login} />
              <PublicRoute path="/register" component={Register} />
              <PublicRoute exact path="/product" component={Product} />
              <PublicRoute exact path="/product-detail/:id" component={ProductDetail} />
              <PublicRoute exact path="/contact" component={Contact} />
              <PublicRoute exact path="/news" component={News} />
              <PublicRoute exact path="/news-detail/:id" component={NewsDetail} />
              <PublicRoute exact path="/project" component={Project} />
              <PublicRoute exact path="/project-detail/:id" component={ProjectDetail} />
              <PublicRoute exact path="/introduce" component={Introduce} />
              <PublicRoute exact path="/document" component={Document} />
              
            </MixLayout>

          </Suspense>
        </Switch>
      </Router>
    </div>
  );
}

function PublicRoute({
  component: TargetPage,
  ...rest
}: any): React.ReactElement {
  return (
    <Route {...rest} render={(props) => <TargetPage {...props} />} />
  );
}


function SecurityRoute({
  component: TargetPage,
  ...rest
}: any): React.ReactElement {
  return (
    <Route {...rest} render={(props) => <TargetPage {...props} />} />
  );
}

export default App;
