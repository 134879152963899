import React from 'react';
import PropTypes from 'prop-types';
import * as S from './style';
import { AppBars } from 'components';

interface Props {
  leftElement?: any,
  centerElement?: any,
  rightElement?: any,
  styleCustom?: any,
  color?: any
}

function Header(props: Props): React.ReactElement{
  const { leftElement, centerElement, rightElement, styleCustom } = props;
  return (
    <S.Wrapper>
      <AppBars
        style={{
          ...styleCustom
        }}
        leftbar={leftElement}
        centerbar={centerElement}
        rightbar={rightElement? rightElement : ''} 
        {...props}
        />
    </S.Wrapper>
  )
}

// Header.propTypes = {
//   leftElement: PropTypes.any,//left elemnt
//   centerElement: PropTypes.any,//center element
//   rightElement: PropTypes.any,//right element
//   styleCustom: PropTypes.any,//styleCustom
//   loading: PropTypes.bool,//loading check
// };

export default Header;