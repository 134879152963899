import styled from "styled-components";
import {
  makeStyles,
  Typography,
  Link,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from "@material-ui/core";

export const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  /* background-image: linear-gradient(to bottom right, #212121, #212121); */
  background-size: cover;
  /* height: 100vh; */
`;

export const Head = styled.div`
  width: 100%;
  background-image: url("http://1.bp.blogspot.com/-xKYdDoOdGNY/WLwahZXVeYI/AAAAAAAAuNw/w3Qr5iFNJJ0io0v-6wrP5aKHabMEm4CqACK4B/s1600/flat_mountains_landscape_by_ggiuliafilippini-d8zdbco.jpg");
  background-size: cover;
  height: 220px;
  text-align: center;
  font-size: larger;
  font-weight: bold;
`;

export const Logo = styled.img`
  max-width: 200px;
`;

export const WrapperSideBar = styled.div`
  width: 100%;
  display: flex;
`;

export const LinkSideBar = styled(Link)`
  position: relative;
  color: #1b2c38;
  cursor: pointer;
  .title{
   padding: 30px 10px;
   display: flex;
  }
  .list-item {
    display: none;
  }
  &:hover {
      color: #27c7cd;
    .list-item {
      display: block;
    }
  }

`;
export const ListItem = styled(Paper)`
  position: absolute;
  top: 81px;
  /* right: 0; */
  z-index: 99;
`;

export const Languages = styled.div`
width: 41px;
height: 41px;
display: flex;
justify-content: center;
align-items: center;
.language{
    display:flex;
    align-items:flex-end;
    flex-direction:column;
    justify-content:center;
    position: relative;
    div{
      cursor:pointer;
    }
  }
  .language-block{
    display: flex;
    align-items: center;
    img{
      margin: 0;
    }
  }
  .language-title{
    font-size: 16px;
    margin-right: 10px;
  }

  p{
    margin:0px;
  }
  img{
    /* width:21px;
    height: 15px; */
    margin-right:10px;
    object-fit: cover;
  }
  .headers{
    justify-content: space-between;
    align-items: center;
    > div{
      &.adrs-line{
        width: 70%;
        span{
          display: block;
          line-break: anywhere;
        }
      }
      &.language{
        width: 30%;
      }
    }
  }

  .pop-content{
    background-color: #ffffff;
    list-style-type: none;
    padding: 20px 0 20px;
    position: absolute;
    top: 30px;
    right: 0;
    z-index: 1;
    min-width: 160px;
    border-radius: 5px;
    box-shadow: 0 2px 2px 0 rgb(153 153 153 / 14%), 0 3px 1px -2px rgb(153 153 153 / 20%), 0 1px 5px 0 rgb(153 153 153 / 12%);
    span{
      color: #212121;
    }
    .close-btn{
      position: absolute;
      right: 7px;
      top: 5px;
      width: 30px;
      height: 30px;
      padding: 0;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      color: #000000;
    }
    button{
      background: none;
      border: none;
      cursor: pointer;
      outline: none !important;
    }
    li{
      padding: 5px 10px;
      button{
        display: flex;
        font-size: 16px;
        align-items: center;
      }
    }
  }
`