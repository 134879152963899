import React from 'react';
import PropTypes from 'prop-types';
import { List, makeStyles } from '@material-ui/core';
// import * as S from './style';

function Dividers(props) {
    const { maxwidth, childrent } = props;

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
            maxWidth: maxwidth || 'inherit',
            backgroundColor: theme.palette.background.paper,
        },
    }));

    const classes = useStyles();

    return <List className={classes.root}>
        {childrent}
    </List>
}

Dividers.propTypes = {
    maxwidth: PropTypes.number,
    childrent: PropTypes.any,
    loading: PropTypes.bool,
};

export default Dividers;