import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

function Breadcrumb(props) {
    const { listItem } = props;

    return (
        <Breadcrumbs maxItems={1} {...props} aria-label="breadcrumb">
            {listItem.map((v) => { return v })}
        </Breadcrumbs>
    );
}

Breadcrumb.propTypes = {
    listItem: PropTypes.array,
    loading: PropTypes.bool,
};

export default Breadcrumb;