import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles, Paper, Container } from "@material-ui/core";
import Carousel from "react-material-ui-carousel";
import banner1 from "assets/img/banner-1.jpg";
import * as S from "./style";

const useStyles = makeStyles((theme) => ({
  itemWrapper: {
    position: "relative",
    "& img": {
      width: "100%",
    },
  },
  title: {
    position: "absolute",
    // zIndex: 9,
    top: 0,
    width: "100%",
    height: "100%",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    fontSize: 32,
    fontWeight: "bold",
    "@media (max-width: 767px)": {
      fontSize: 20,
    },
  },
}));

function CarouselResponsive(props) {
  const { t } = useTranslation("common");

  const classes = useStyles();
  const items = [
    {
      title:t("text.header"),
      description: "Probably the most random thing you have ever seen!",
      url: banner1,
    },
    // {
    //   title: "NHÀ PHÂN PHỐI THIẾT BỊ ĐIỆN",
    //   description: "Probably the most random thing you have ever seen!",
    //   url: banner1,
    // },
  ];

  return (
    <S.Wrapper
      id="carousel"
      className="carousel-wrapper"
      style={{ position: "relative", width: "100%",backgroundColor: 'rgb(241, 242, 236)' }}
    >
      <S.CustomContainer maxWidth="maxWidthXl"  className="abc">
        <Carousel
          indicatorContainerProps={{
            style: {
              position: "absolute",
              bottom: "10px",
              zIndex: 8,
            },
          }}
        >
          {items.map((item, i) => (
            <Paper key={i} className={classes.itemWrapper}>
              <img src={item.url ?? ""} alt="banner" />
              <div className={classes.title}>
                <Container maxWidth="md">
                  <p>{item.title}</p>
                </Container>
              </div>
            </Paper>
          ))}
        </Carousel>
      </S.CustomContainer>
    </S.Wrapper>
  );
}

CarouselResponsive.propTypes = {
  // autoplay: PropTypes.bool,
  // interval: PropTypes.number,
  // label: PropTypes.string,
  // landscape: PropTypes.bool,
  // mobile: PropTypes.bool,
  // open: PropTypes.bool,
  // onChange: PropTypes.func,
  // onClose: PropTypes.func,
  // propsValues: PropTypes.array,
};

export default CarouselResponsive;
