import React from 'react';
import Rating from '@material-ui/lab/Rating';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';

function Ratings(props) {
	const { ratingProps, value, setValueRating, setValueHover, type, color, size } = props;
	const [valueRating, setValue] = React.useState(value);
	const [hover, setHover] = React.useState(-1);
	const labels = {
		0.5: 'Useless',
		1: 'Useless+',
		1.5: 'Poor',
		2: 'Poor+',
		2.5: 'Ok',
		3: 'Ok+',
		3.5: 'Good',
		4: 'Good+',
		4.5: 'Excellent',
		5: 'Excellent+',
	};

	const styleRating = {
		root:{
			fontSize:size,
		},
		iconFilled: {
			color: color,
		},
		iconHover: {
			color: color,
			filter: 'brightness(80%)',
		},
	};
	const StyleRating = withStyles(styleRating)(Rating);
	if (type && type === 'Feedback') {
		return (
			<>
				<StyleRating
					value={valueRating}
					onChange={(event, newValue) => {
						setValue(newValue);
						setValueRating && setValueRating(newValue);
					}}
					onChangeActive={(event, newHover) => {
						setHover(newHover);
						setValueHover && setValueHover(newHover);
					}}
					{...ratingProps}
				></StyleRating>

				{valueRating !== null && <Box ml={2}>{labels[hover !== -1 ? hover : valueRating]}</Box>}
			</>
		);
	}
	return (
		<StyleRating
			value={valueRating}
			onChange={(event, newValue) => {
				setValue(newValue);
				setValueRating && setValueRating(newValue);
			}}
			onChangeActive={(event, newHover) => {
				setHover(newHover);
				setValueHover && setValueHover(newHover);
			}}
			{...ratingProps}
		></StyleRating>
	);
}

Ratings.propTypes = {
	ratingProps: PropTypes.object,
	count: PropTypes.number,
	url: PropTypes.string,
	type: PropTypes.string,
	horizontal: PropTypes.string,
	backgroundColor: PropTypes.string,
	color: PropTypes.string,
	size: PropTypes.number,
};

export default Ratings;
