import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const CustomTooltipWrapper = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#000",
    color: "#fff",
    fontSize: 11,
  },
  arrow: {
    color: "#000",
  },
}))(Tooltip);

function CustomTooltip(props) {
  const { placement, title } = props;

  return (
    <CustomTooltipWrapper placement={placement} title={title} arrow>
      {props.children}
    </CustomTooltipWrapper>
  );
}

CustomTooltip.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  placement: PropTypes.string,
};

export default CustomTooltip;
