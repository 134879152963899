import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';


interface Props {
  leftbar?: any,
  centerbar?: any,
  rightbar?: any,
  style?: any
}


function AppBars(props: Props) {
  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
      },
      toolbar: {
        display: 'flex',
        justifyContent: 'space-between'
      },
      leftbar: {
        display: 'flex',
        alignItems: 'center'
      },
      centerbar: {
        display: 'flex',
        alignItems: 'center'
      },
      rightbar: {
        display: 'flex',
        alignItems: 'center'
      },
    })
  );

  const classes = useStyles();

  return <div className={classes.root}>
    <AppBar position="static" {...props}>
      <Container>
        <Toolbar className={classes.toolbar}>
          <div className={classes.leftbar}>
            {props.leftbar}
          </div>
          <div className={classes.centerbar}>
            {props.centerbar}
          </div>
          <div className={classes.rightbar}>
            {props.rightbar}
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  </div>
}

// AppBars.propTypes = {
//   leftbar: PropTypes.node,
//   centerbar: PropTypes.node,
//   rightbar: PropTypes.node
// };

export default AppBars;