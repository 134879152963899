import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import GoogleMapReact from 'google-map-react';
import * as S from './style';
import PropTypes from 'prop-types';

import { MdPlace } from 'react-icons/md';

const AnyReactComponent = ({ text }) => <div>{text}</div>;
function GoogleMap(props) {
	const { width, height, size, colorMarker, iconPlace } = props;
	const center = {
		lat: 59.95,
		lng: 30.33,
	};
	const [position, setPosition] = useState(center);
	// useEffect(() => {
	// 	initMap();
	// 	// eslint-disable-next-line
	// }, []);

	// console.log('google', window.google);
	// // Initialize and add the map
	// const initMap = () => {
	// 	// The location of Uluru
	// 	const uluru = { lat: -25.344, lng: 131.036 };
	// 	// The map, centered at Uluru
	// 	const map = new window.google.maps.Map(document.getElementById('mapGoogle'), {
	// 		zoom: 4,
	// 		center: uluru,
	// 	});
	// 	// The marker, positioned at Uluru
	// 	const marker = new window.google.maps.Marker({
	// 		position: uluru,
	// 		map: map,
	// 	});
	// };
	const handleApiLoaded = (map, maps) => {
		// use map and maps objects
		console.log('map :>> ', map);
		console.log('maps :>> ', maps);
	};
	const handleClick = (e) => {
		console.log('e', e);
		setPosition({ lat: e.lat, lng: e.lng });
	};
	return (
		<S.MapWrap style={{ width: width, height: height }}>
			<GoogleMapReact
				// bootstrapURLKeys={{ key: 'AIzaSyAshvppxfZSUvsWnKsHw87edk95zhmc-os' }}
				panControl= {false}
				mapTypeControl= {false}
				defaultCenter={center}
				defaultZoom={11}
				yesIWantToUseGoogleMapApiInternals
				onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
				onClick={(e) => handleClick(e)}
			>
				<S.Marker
					style={{ fontSize: `${size}px`, marginLeft: `-${size / 2}px`, marginTop: `-${size / 2}px`, color:colorMarker }}
					lat={position.lat}
					lng={position.lng}
				>
					{iconPlace?iconPlace:<MdPlace />}
				</S.Marker>
			</GoogleMapReact>
		</S.MapWrap>
	);
}
Alert.propTypes = {
	width: PropTypes.string,
	height: PropTypes.string,
	size: PropTypes.number,
	colorMarker: PropTypes.string,
	iconPlace: PropTypes.any,
};

export default GoogleMap;
