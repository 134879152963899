import Endpoints from "constants/endpoints";
import { mainAxios, mainAxiosToken } from "libs/axios";

export async function getDocuments() {
  return await mainAxios
    .post(Endpoints.LIST_DOCUMENT)
    .then((response: any) => {
      // console.log("response", response);
      return response;
    })
    // 응답(실패)
    .catch(() => {
      return null;
    });
}
