import axios from "axios";
import { getLocalStorage } from "utils/localStorage";

const { REACT_APP_SERVER_RESERVE_URL } = process.env;

/** Get token from sessionStorage */
let token = getLocalStorage("ELECTRIC");
// console.log("token", token);
// if (token !== '' && token !== null && token !== undefined) token = token;

/** Create Header Request*/
const headerDict = {
  // Accept: 'application/json',
  Authorization: `Bearer ${token}`,
};

export const mainAxios = axios.create({
  baseURL: REACT_APP_SERVER_RESERVE_URL,
  // withCredentials: true,
});

/** Use if have token */
export const mainAxiosToken = axios.create({
  baseURL: REACT_APP_SERVER_RESERVE_URL,
  headers: headerDict,
  withCredentials: true,
});

export const reserveAxios = axios.create({
  baseURL: `${REACT_APP_SERVER_RESERVE_URL}/api`,
  withCredentials: true,
});
