import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabList from '@material-ui/lab/TabList';
import TabContext from '@material-ui/lab/TabContext';
import TabPanel from '@material-ui/lab/TabPanel';

function TabsComponent(props) {
    const useStyles = makeStyles(() => ({
        root: {
            textAlign: 'initial',
            ...props.stylecontent
        },

        vertical: {
            display: 'flex',

        }
    }));

    const classes = useStyles();
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return <div className={classes.root}>
        <TabContext value={value}>
            <AppBar position="static">
                <TabList
                    {...props}
                    onChange={handleChange}
                    >

                    {props.data.map((item, index) => (
                        <Tab key={index} icon={item.icon} label={item.label} value={`${index}`} />
                    ))}
                </TabList>
            </AppBar>
            {props.data.map((item, index) => (
                <TabPanel key={index} value={`${index}`} index={index}>{item.content}</TabPanel>
            ))}           
        </TabContext>
    </div>
}

TabsComponent.propTypes = {
    children: PropTypes.node,
    data: PropTypes.array,
    stylecontent: PropTypes.object
};

export default TabsComponent;