import React, { useState } from 'react';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

function ToggleButtons(props) {
	const { toggleBtnProps, listButton, setValue, valueDefault, multipleSelection, bgrColor, color } = props;

	const styleToggleButton = {
		root: {
			color: color,
			backgroundColor: bgrColor,
			'& .Mui-selected':{
				color: color,
			backgroundColor: bgrColor,
			filter: 'brightness(80%)',
			}
		},
		
	};
	const StyleToggleButton = withStyles(styleToggleButton)(ToggleButton);

	const [valueBtn, setValueBtn] = useState(valueDefault && valueDefault);

	const handleAlignment = (event, newAlignment) => {
		setValue && setValue(newAlignment);
		setValueBtn(newAlignment);
	};
	const list =
		listButton &&
		listButton.map((item, index) => {
			return (
				<StyleToggleButton key={index} value={item && item.title} aria-label={item && item.title}>
					{item && item.icon}
				</StyleToggleButton>
			);
		});
	return (
		<ToggleButtonGroup
			value={valueBtn}
			exclusive={multipleSelection ? multipleSelection : false}
			onChange={handleAlignment}
			{...toggleBtnProps}
		>
			{list && list}
		</ToggleButtonGroup>
	);
}

ToggleButtons.propTypes = {
	toggleBtnProps: PropTypes.object,
	listButton: PropTypes.array,
	setValue: PropTypes.any,
	valueDefault: PropTypes.any,
	title: PropTypes.any,
	icon: PropTypes.object,
	multipleSelection: PropTypes.bool,
	bgrColor: PropTypes.string,
	color: PropTypes.string,
};

export default ToggleButtons;
