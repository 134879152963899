import React from 'react';
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';

function Sliders(props) {
	const { sliderProps,sizeSlider, styleThum, colorSlider } = props;
	const styleSlider = {
		root: {
			color: colorSlider,
			height: sizeSlider,
		},
		thumb: {
			...styleThum,
			'&:focus, &:hover, &$active': {
				boxShadow: 'inherit',
			},
			'& .bar': {
				height: 9,
				width: 1,
				backgroundColor: 'currentColor',
				marginLeft: 1,
				marginRight: 1,
			},
		},
		mark: {
			height: sizeSlider,
			width: sizeSlider,
			borderRadius: sizeSlider,
		},
		markLabel:{
			top: 18+sizeSlider
		},
		active: {},
		valueLabel: {
			left: `calc(-50% + `+ (sizeSlider/2)+`px)`,
		},
		track: {
			height: sizeSlider,
			borderRadius: sizeSlider/2,
		},
		rail: {
			height: sizeSlider,
			borderRadius: sizeSlider/2,
		},
	};
	const StyleSlider = withStyles(styleSlider)(Slider);
	return <StyleSlider {...sliderProps} />;
}

Sliders.propTypes = {
	sizeSlider: PropTypes.number,
	colorSlider: PropTypes.string,
	defaultValue: PropTypes.number || PropTypes.array,
	getAriaValueText: PropTypes.func,
	ariaLabelledby: PropTypes.string,
	step: PropTypes.number,
	min: PropTypes.number,
	max: PropTypes.number,
	valueLabelDisplay: PropTypes.string,
	sliderProps: PropTypes.object,
	ThumbComponent: PropTypes.object,
	styleThum: PropTypes.object,
};

export default Sliders;
